import React, {Component} from 'react';

import queryString from 'query-string';

import Header from './Header';
import Sidebar from './Sidebar';
import Shortcuts from './Shortcuts';
import Breadcrumbs from './Breadcrumbs';
import {Link} from 'react-router-dom';

import Plugins from '../utils/Plugins';

import Helper from '../utils/Helper';

import PackagesDepositPaymentAPI from '../services/PackagesDepositPayment';

import './packagespayment.css';
import '../assets/css/dataTables.material.min.css';

class PacketsDepositPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancelled: false,
      isProcessing: false,
      isRefused: false,
      isApproved: false,
      isAll: false,
      cancelledCnt: 0,
      processingCnt: 0,
      refusedCnt: 0,
      approvedCnt: 0,
      allCnt: 0,
      packages: null,
    };

    this.props.history.listen((location, action) => {
      this.parseStateFromLocation(location.search);
    });
  }

  parseStateFromLocation = locationSearch => {
    const values = queryString.parse(locationSearch);

    this.setState(
      {
        isCancelled: typeof values['cancelados'] !== 'undefined',
        isProcessing:
          typeof values['processando'] !== 'undefined' ||
          (Object.keys(values).length === 0 &&
            typeof values === 'object'),
        isRefused: typeof values['recusados'] !== 'undefined',
        isApproved: typeof values['aprovados'] !== 'undefined',
        isAll: typeof values['todos'] !== 'undefined',
      },
      () => {
        this.updatePackages();
      },
    );
  };

  async updatePackages() {
    Plugins.destroyTables();
    let packages = null;

    if (this.state.isCancelled) {
      packages = await PackagesDepositPaymentAPI.getCancelledPackages();
    } else if (this.state.isProcessing) {
      packages = await PackagesDepositPaymentAPI.getProcessingPackages();
    } else if (this.state.isRefused) {
      packages = await PackagesDepositPaymentAPI.getRefusedPackages();
    } else if (this.state.isApproved) {
      packages = await PackagesDepositPaymentAPI.getApprovedPackages();
    } else if (this.state.isAll) {
      packages = await PackagesDepositPaymentAPI.getAllPackages();
    }

    this.setState({packages}, () => {
      Plugins.initDataTables();
      M.AutoInit();
    });
  }

  async componentDidMount() {
    this.parseStateFromLocation(this.props.location.search);

    let cancelledCnt = await PackagesDepositPaymentAPI.getCancelledPackagesCount();
    let processingCnt = await PackagesDepositPaymentAPI.getProcessingPackagesCount();
    let refusedCnt = await PackagesDepositPaymentAPI.getRefusedPackagesCount();
    let approvedCnt = await PackagesDepositPaymentAPI.getApprovedPackagesCount();
    let allCnt = await PackagesDepositPaymentAPI.getAllPackagesCount();

    this.setState({
      cancelledCnt,
      processingCnt,
      refusedCnt,
      approvedCnt,
      allCnt,
    });
  }

  render() {
    const {
      packages,
      cancelledCnt,
      processingCnt,
      refusedCnt,
      approvedCnt,
      allCnt,
      isCancelled,
      isProcessing,
      isRefused,
      isApproved,
      isAll,
    } = this.state;

    let actionCrumb = {
      label: isCancelled
        ? 'Cancelados'
        : isProcessing
        ? 'Processando'
        : isRefused
        ? 'Recusados'
        : isApproved
        ? 'Aprovados'
        : isAll
        ? 'Todos'
        : null,
      url: '#',
    };

    return (
      <React.Fragment>
        <Header />

        <Sidebar />

        <div id="main">
          <div className="row">
            <div className="col s12">
              <div className="container">
                <Shortcuts />
                <Breadcrumbs
                  crumbs={[
                    {label: 'Dashboard', url: '/dashboard'},
                    {label: 'Pacotes', url: '/pacotes'},
                    {
                      label: 'Pagamentos com depósito bancário',
                      url: '/pgtos_pacotes_deposito',
                    },
                    actionCrumb,
                  ]}
                />
                <div id="packetFilters" className="flex align-center">
                  <Link
                    to="/pgtos_pacotes_deposito?processando"
                    className="flex align-center btn orange white-text">
                    <i className="material-icons left">
                      rotate_right
                    </i>
                    Processando
                    {processingCnt ? (
                      <span className="badge right white-text grey darken-1">
                        {processingCnt}
                      </span>
                    ) : null}
                  </Link>
                  <Link
                    to="/pgtos_pacotes_deposito?cancelados"
                    className="btn red white-text flex align-center">
                    <i className="material-icons left">thumb_down</i>
                    Cancelados
                    {cancelledCnt ? (
                      <span className="badge right white-text grey darken-1">
                        {cancelledCnt}
                      </span>
                    ) : null}
                  </Link>
                  <Link
                    to="/pgtos_pacotes_deposito?recusados"
                    className="btn white-text indigo darken-3 flex align-center">
                    <i className="material-icons left">thumb_down</i>
                    Recusados
                    {refusedCnt ? (
                      <span className="badge right white-text grey darken-1">
                        {refusedCnt}
                      </span>
                    ) : null}
                  </Link>
                  <Link
                    to="/pgtos_pacotes_deposito?aprovados"
                    className="btn white-text green lighten-2 flex align-center">
                    <i className="material-icons left">thumb_up</i>
                    Aprovados
                    {approvedCnt ? (
                      <span className="badge right white-text grey darken-1">
                        {approvedCnt}
                      </span>
                    ) : null}
                  </Link>
                  <Link
                    to="/pgtos_pacotes_deposito?todos"
                    className="btn white-text blue lighten-2 flex align-center">
                    <i className="material-icons left">list</i>
                    Todos
                    {allCnt ? (
                      <span className="badge right white-text grey darken-1">
                        {allCnt}
                      </span>
                    ) : null}
                  </Link>
                </div>
                <div className="divider" />

                <span>
                  <div>
                    <table
                      id=""
                      className="striped highlight datatable">
                      <thead>
                        <tr>
                          <th>Pacote</th>
                          <th>Data</th>
                          <th>User</th>
                          <th>AV</th>
                          <th>Banco</th>
                          <th>Valor</th>
                          <th>Anexo</th>
                          <th>Status</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        {packages &&
                          packages.map(el => (
                            <tr key={el.id}>
                              <td>{el.packageId}</td>
                              <td>
                                {Helper.formatDate(
                                  'dd/MM/yyyy à\\s hh:MM:ss',
                                  el.date,
                                )}
                              </td>
                              <td>
                                <a
                                  href="#"
                                  className="btn waves-effect white black-text">
                                  {el.user.id} / {el.user.name}
                                </a>
                              </td>

                              <td>
                                {el.av == 'SA' ? (
                                  <span
                                    className="badge white-text grey lighten-1 tooltipped"
                                    data-tooltip="Sem avaliação - Cliente"
                                    data-position="top">
                                    SA
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td>{el.bank.toUpperCase()}</td>
                              <td>
                                <span className="orange-text nowrap">
                                  {Helper.formatCurrency(
                                    el.value,
                                    'R$',
                                  )}
                                </span>
                              </td>
                              <td>
                                <a
                                  href={el.attachment}
                                  className="tooltipped"
                                  data-tooltip="Comprovante de pagamento"
                                  data-position="top">
                                  <i className="black-text fas fa-barcode fa-2x" />
                                </a>
                              </td>
                              <td>
                                {el.status.toLowerCase() ==
                                'processando' ? (
                                  <span className="badge waves-effect orange white-text">
                                    {el.status}
                                  </span>
                                ) : el.status.toLowerCase() ==
                                  'cancelado' ? (
                                  <span className="badge waves-effect red white-text">
                                    {el.status}
                                  </span>
                                ) : el.status.toLowerCase() ==
                                  'recusado' ? (
                                  <span className="badge waves-effect indigo darken-3 white-text">
                                    {el.status}
                                  </span>
                                ) : el.status.toLowerCase() ==
                                  'aprovado' ? (
                                  <span className="badge waves-effect green lighten-2 white-text">
                                    {el.status}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="actions">
                                <span
                                  className="badge orange tooltipped"
                                  data-tooltip="Editar pacote"
                                  data-position="top">
                                  <a href="" className="white-text">
                                    <i className="material-icons">
                                      edit
                                    </i>
                                  </a>
                                </span>
                                <span
                                  className="badge blue lighten-2 tooltipped"
                                  data-tooltip="Editar Status"
                                  data-position="top">
                                  <a href="" className="white-text">
                                    <i className="material-icons">
                                      info
                                    </i>
                                  </a>
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PacketsDepositPayment;
