import React, {Component} from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Shortcuts from './Shortcuts';
import Breadcrumbs from './Breadcrumbs';

import M from 'materialize-css';

import './antifraud.css';

class AntiFraud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchMade: false,
    };
  }

  componentDidUpdate() {
    //M.AutoInit();
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <Sidebar />

        <div id="main">
          <div className="row">
            <div className="col s12">
              <div className="container">
                <Shortcuts />
                <Breadcrumbs
                  crumbs={[
                    {label: 'Dashboard', url: '/dashboard'},
                    {
                      label: 'Anti-Fraude',
                      url: '/anti_fraud',
                    },
                  ]}
                />
              </div>
            </div>
            <div className="col s12">
              <div className="container">
                <div className="row">
                  <div className="col s3">
                    <span className="badge orange left searchResultBadge">
                      <i className="material-icons left">search</i>
                      {this.searchMade
                        ? '79702 / Suelito Moraes'
                        : 'NENHUMA PESQUISA REALIZADA'}
                    </span>
                  </div>
                  <form>
                    <div className="col s3 push-s6 input-field flex">
                      <div>
                        <input id="first_name2" type="text" />
                        <label
                          className="active"
                          htmlFor="first_name2">
                          First Name
                        </label>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="btn blue lighten-2 white-text">
                          <i className="material-icons">
                            filter_list
                          </i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {this.state.searchMade ? (
                  <table
                    id=""
                    className="striped highlight datatable">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Usado por</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="https://geoiptool.com/pt/?ip=177.76.95.77"
                            target="_blank">
                            <span className="badge white black-text">
                              <i className="material-icons left">
                                search
                              </i>
                              177.76.95.77
                            </span>
                          </a>
                        </td>
                        <td>
                          <span className="badge green">
                            NÃO FOI USADO
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://geoiptool.com/pt/?ip=179.131.202.15"
                            target="_blank">
                            <span className="badge white black-text">
                              <i className="material-icons left">
                                search
                              </i>
                              179.131.202.15
                            </span>
                          </a>
                        </td>
                        <td>
                          <a href="#">
                            <span
                              className="badge red tooltipped"
                              data-tooltip="Visualizar usuário"
                              data-position="top">
                              45362
                            </span>
                          </a>
                          <a href="#">
                            <span
                              className="badge red tooltipped"
                              data-tooltip="Visualizar usuário"
                              data-position="top">
                              2844
                            </span>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://geoiptool.com/pt/?ip=179.131.202.15"
                            target="_blank">
                            <span className="badge white black-text">
                              <i className="material-icons left">
                                search
                              </i>
                              179.131.202.15
                            </span>
                          </a>
                        </td>
                        <td>
                          <a href="#">
                            <span
                              className="badge red tooltipped"
                              data-tooltip="Visualizar usuário"
                              data-position="top">
                              45362
                            </span>
                          </a>
                          <a href="#">
                            <span
                              className="badge red tooltipped"
                              data-tooltip="Visualizar usuário"
                              data-position="top">
                              2844
                            </span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AntiFraud;
