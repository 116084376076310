import React, {Component} from 'react';

import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import Shortcuts from '../Shortcuts';

import Plugins from '../../utils/Plugins';

import './style.css';

class Dashboard extends Component {
  componentDidMount() {
    new Plugins();
    //pluginsUtils();
  }

  componentDidUpdate() {
    //pluginsUtils();
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <Sidebar />

        <div id="main">
          <div className="row">
            <div className="col s12">
              <div className="container">
                <Shortcuts />

                <div id="datepickerWrapper">
                  <div className="input-field s12">
                    <i className="material-icons prefix">
                      filter_list
                    </i>
                    <input
                      id="icon_prefix"
                      type="text"
                      className="datepicker"
                    />
                    <label htmlFor="icon_prefix">Filtrar</label>
                  </div>
                </div>

                <div id="card-stats">
                  <div className="row">
                    <div className="col s12 m6 l6 xl3">
                      <div className="card gradient-45deg-indigo-light-blue gradient-shadow min-height-100 white-text animate fadeLeft">
                        <div className="padding-4">
                          <div className="row">
                            <div className="col s2 m2">
                              <i className="material-icons background-round mt-5">
                                storage
                              </i>
                            </div>
                            <div className="col s10 m10 right-align">
                              <h5 className="mb-0 white-text">0</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col s12">
                              <p>Estoque usuário</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="collection">
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                      </div>
                    </div>
                    <div className="col s12 m6 l6 xl3">
                      <div className="card gradient-45deg-indigo-light-blue gradient-shadow min-height-100 white-text animate fadeLeft">
                        <div className="padding-4">
                          <div className="row">
                            <div className="col s2 m2">
                              <i className="material-icons background-round mt-5">
                                shopping_basket
                              </i>
                            </div>
                            <div className="col s10 m10 right-align">
                              <h5 className="mb-0 white-text">0</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col s12">
                              <p>Produtos estoque</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="collection">
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                      </div>
                    </div>
                    <div className="col s12 m6 l6 xl3">
                      <div className="card gradient-45deg-green-teal gradient-shadow min-height-100 white-text animate fadeRight">
                        <div className="padding-4">
                          <div className="row">
                            <div className="col s2 m2">
                              <i className="material-icons background-round mt-5">
                                perm_identity
                              </i>
                            </div>
                            <div className="col s10 m10 right-align">
                              <h5 className="mb-0 white-text">0</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col s12">
                              <p>Usuários</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="collection">
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                      </div>
                    </div>
                    <div className="col s12 m6 l6 xl3">
                      <div className="card gradient-45deg-red-pink gradient-shadow min-height-100 white-text animate fadeRight">
                        <div className="padding-4">
                          <div className="row">
                            <div className="col s2 m2">
                              <i className="material-icons background-round mt-5">
                                archive
                              </i>
                            </div>
                            <div className="col s10 m10 right-align">
                              <h5 className="mb-0 white-text">0</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col s12">
                              <p>Pacotes Logs</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="collection">
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">1</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">3</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">20</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">50</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">60</span>
                        </a>
                        <a
                          href="#!"
                          className="black-text collection-item">
                          Alvin
                          <span className="badge black-text">20</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Dashboard;
