import React, {Component} from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Shortcuts from './Shortcuts';
import Breadcrumbs from './Breadcrumbs';

import {Link} from 'react-router-dom';

class AuthorizedPackages extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />

        <Sidebar />

        <div id="main">
          <div className="row">
            <div className="col s12">
              <div className="container">
                <Shortcuts />
                <Breadcrumbs
                  crumbs={[
                    {label: 'Dashboard', url: '/dashboard'},
                    {
                      label: 'Pacotes autorizados',
                      url: '/pacotes_autorizados',
                    },
                  ]}
                />
                <div className="topFilters flex align-center">
                  <a
                    href="#"
                    className="btn white black-text flex align-center">
                    Pacotes
                    <span className="badge right white-text blue darken-3">
                      38
                    </span>
                  </a>
                  <Link
                    to="/anexos_avulsos"
                    className="btn blue lighten-2">
                    <i className="material-icons left">attachment</i>
                    Anexos avulsos
                  </Link>
                  <a
                    href="/pacotes_autorizados"
                    className="btn green lighten-2 flex align-center">
                    <i className="material-icons left">file_upload</i>
                    Pacotes Autorizados
                    <span className="badge blue darken-3">2</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col s12">
              <div className="container">
                <table
                  id="searchResultsTable"
                  className="striped highlight">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Aprovado</th>
                      <th>ID / Usuário</th>
                      <th>CV</th>
                      <th>Peso</th>
                      <th>Valor</th>
                      <th>Pagamento</th>
                      <th>Tipo</th>
                      <th>Status</th>
                      <th>OK?</th>
                      <th>Ações</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>284425</td>
                      <td>06/03/2018 às 15:52:44</td>
                      <td>
                        <a
                          href="#"
                          className="btn waves-effect white black-text tooltipped"
                          data-tooltip="Visualizar usuário"
                          data-position="top">
                          79702 / Eclair
                        </a>
                      </td>
                      <td>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Conta verificada"
                          data-position="top">
                          S
                        </span>
                      </td>
                      <td>19</td>
                      <td>US$ 138.75 138.12 + 0.63</td>
                      <td>
                        <span
                          className="badge blue lighten-2 tooltipped"
                          data-tooltip="Cartão de crédito"
                          data-position="top">
                          C
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge blue lighten-2 tooltipped"
                          data-tooltip="First class"
                          data-position="top">
                          FC
                        </span>
                      </td>
                      <td>
                        <span className="badge green lighten-2">
                          Aprovado
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge red tooltipped"
                          data-tooltip="Confirmar pacote"
                          data-position="top">
                          <a href="#" className="white-text">
                            N
                          </a>
                        </span>
                        <span
                          className="badge orange tooltipped"
                          data-tooltip="Confirmar/Autorizar envio"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">
                              file_upload
                            </i>
                          </a>
                        </span>
                      </td>
                      <td className="actions">
                        <span
                          className="badge orange tooltipped"
                          data-tooltip="Editar pacote"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">edit</i>
                          </a>
                        </span>
                        <span
                          className="badge white tooltipped"
                          data-tooltip="Ticket de interação com o suporte"
                          data-position="top">
                          <a href="" className="black-text">
                            <i className="material-icons">
                              confirmation_number
                            </i>
                          </a>
                        </span>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Enviar pacote"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">forward</i>
                          </a>
                        </span>
                        <span
                          className="badge blue lighten-2 tooltipped"
                          data-tooltip="Gerar XML"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">link</i>
                          </a>
                        </span>
                        <span
                          className="badge blue darken-3 tooltipped"
                          data-tooltip="Solicitar documentação"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">person</i>
                          </a>
                        </span>
                        <span
                          className="badge white lighten-2 tooltipped"
                          data-tooltip="Adicionar nota"
                          data-position="top">
                          <a href="" className="black-text">
                            <i className="material-icons">
                              insert_drive_file
                            </i>
                          </a>
                        </span>
                        <span className="badge blue darken-3">
                          <a href="" className="white-text">
                            <i className="material-icons">
                              remove_red_eye
                            </i>
                          </a>
                        </span>
                        <span
                          className="badge red tooltipped"
                          data-tooltip="Autorizar envio"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">
                              thumb_down
                            </i>
                          </a>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>284425</td>
                      <td>06/03/2018 às 15:52:44</td>
                      <td>
                        <a
                          href="#"
                          className="btn waves-effect white black-text tooltipped"
                          data-tooltip="Visualizar usuário"
                          data-position="top">
                          79702 / Eclair
                        </a>
                      </td>
                      <td>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Conta verificada"
                          data-position="top">
                          S
                        </span>
                      </td>
                      <td>19</td>
                      <td>US$ 138.75 138.12 + 0.63</td>
                      <td>
                        <span
                          className="badge blue lighten-2 tooltipped"
                          data-tooltip="Cartão de crédito"
                          data-position="top">
                          C
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge blue lighten-2 tooltipped"
                          data-tooltip="USPS Priority"
                          data-position="top">
                          UP
                        </span>
                      </td>
                      <td>
                        <span className="badge green lighten-2">
                          Aprovado
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge red tooltipped"
                          data-tooltip="Confirmar pacote"
                          data-position="top">
                          <a href="#" className="white-text">
                            N
                          </a>
                        </span>
                        <span
                          className="badge orange tooltipped"
                          data-tooltip="Confirmar/Autorizar envio"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">
                              file_upload
                            </i>
                          </a>
                        </span>
                      </td>
                      <td className="actions">
                        <span
                          className="badge orange tooltipped"
                          data-tooltip="Editar pacote"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">edit</i>
                          </a>
                        </span>
                        <span
                          className="badge white tooltipped"
                          data-tooltip="Ticket de interação com o suporte"
                          data-position="top">
                          <a href="" className="black-text">
                            <i className="material-icons">
                              confirmation_number
                            </i>
                          </a>
                        </span>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Enviar pacote"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">forward</i>
                          </a>
                        </span>
                        <span
                          className="badge blue lighten-2 tooltipped"
                          data-tooltip="Gerar XML"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">link</i>
                          </a>
                        </span>
                        <span
                          className="badge blue darken-3 tooltipped"
                          data-tooltip="Solicitar documentação"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">person</i>
                          </a>
                        </span>
                        <span
                          className="badge white lighten-2 tooltipped"
                          data-tooltip="Adicionar nota"
                          data-position="top">
                          <a href="" className="black-text">
                            <i className="material-icons">
                              insert_drive_file
                            </i>
                          </a>
                        </span>
                        <span className="badge blue darken-3">
                          <a href="" className="white-text">
                            <i className="material-icons">
                              remove_red_eye
                            </i>
                          </a>
                        </span>
                        <span
                          className="badge red tooltipped"
                          data-tooltip="Autorizar envio"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">
                              thumb_down
                            </i>
                          </a>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>284425</td>
                      <td>06/03/2018 às 15:52:44</td>
                      <td>
                        <a
                          href="#"
                          className="btn waves-effect white black-text tooltipped"
                          data-tooltip="Visualizar usuário"
                          data-position="top">
                          79702 / Eclair
                        </a>
                      </td>
                      <td>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Conta verificada"
                          data-position="top">
                          S
                        </span>
                      </td>
                      <td>19</td>
                      <td>US$ 138.75 138.12 + 0.63</td>
                      <td>
                        <span
                          className="badge blue lighten-2 tooltipped"
                          data-tooltip="Cartão de crédito"
                          data-position="top">
                          C
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge orange lighten-2 tooltipped"
                          data-tooltip="Fedex priority"
                          data-position="top">
                          FP
                        </span>
                      </td>
                      <td>
                        <span className="badge green lighten-2">
                          Aprovado
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge red tooltipped"
                          data-tooltip="Confirmar pacote"
                          data-position="top">
                          <a href="#" className="white-text">
                            N
                          </a>
                        </span>
                        <span
                          className="badge orange tooltipped"
                          data-tooltip="Confirmar/Autorizar envio"
                          data-position="top">
                          <a
                            href=""
                            className="white-text tooltipped"
                            data-tooltip="Confirmar/Autorizar envio"
                            data-position="top">
                            <i className="material-icons">
                              file_upload
                            </i>
                          </a>
                        </span>
                      </td>
                      <td className="actions">
                        <span
                          className="badge orange tooltipped"
                          data-tooltip="Editar pacote"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">edit</i>
                          </a>
                        </span>
                        <span
                          className="badge white tooltipped"
                          data-tooltip="Ticket de interação com o suporte"
                          data-position="top">
                          <a href="" className="black-text">
                            <i className="material-icons">
                              confirmation_number
                            </i>
                          </a>
                        </span>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Enviar pacote"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">forward</i>
                          </a>
                        </span>
                        <span
                          className="badge blue lighten-2 tooltipped"
                          data-tooltip="Gerar XML"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">link</i>
                          </a>
                        </span>
                        <span
                          className="badge blue darken-3 tooltipped"
                          data-tooltip="Solicitar documentação"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">person</i>
                          </a>
                        </span>
                        <span
                          className="badge white lighten-2 tooltipped"
                          data-tooltip="Adicionar nota"
                          data-position="top">
                          <a href="" className="black-text">
                            <i className="material-icons">
                              insert_drive_file
                            </i>
                          </a>
                        </span>
                        <span className="badge blue darken-3">
                          <a href="" className="white-text">
                            <i className="material-icons">
                              remove_red_eye
                            </i>
                          </a>
                        </span>
                        <span
                          className="badge red tooltipped"
                          data-tooltip="Autorizar envio"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">
                              thumb_down
                            </i>
                          </a>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>284425</td>
                      <td>06/03/2018 às 15:52:44</td>
                      <td>
                        <a
                          href="#"
                          className="btn waves-effect white black-text tooltipped"
                          data-tooltip="Visualizar usuário"
                          data-position="top">
                          79702 / Eclair
                        </a>
                      </td>
                      <td>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Conta verificada"
                          data-position="top">
                          S
                        </span>
                      </td>
                      <td>19</td>
                      <td>US$ 138.75 138.12 + 0.63</td>
                      <td>
                        <span
                          className="badge white black-text lighten-2 tooltipped"
                          data-tooltip="Depósito bancário"
                          data-position="top">
                          D
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge blue lighten-2 tooltipped"
                          data-tooltip="USPS priority"
                          data-position="top">
                          UP
                        </span>
                      </td>
                      <td>
                        <span className="badge green lighten-2">
                          Aprovado
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Não confirmar pacote"
                          data-position="top">
                          <a href="#" className="white-text">
                            S
                          </a>
                        </span>
                        <span
                          className="badge orange tooltipped"
                          data-tooltip="Confirmar/Autorizar envio"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">
                              file_upload
                            </i>
                          </a>
                        </span>
                      </td>
                      <td className="actions">
                        <span
                          className="badge orange tooltipped"
                          data-tooltip="Editar pacote"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">edit</i>
                          </a>
                        </span>
                        <span
                          className="badge white tooltipped"
                          data-tooltip="Ticket de interação com o suporte"
                          data-position="top">
                          <a href="" className="black-text">
                            <i className="material-icons">
                              confirmation_number
                            </i>
                          </a>
                        </span>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Enviar pacote"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">forward</i>
                          </a>
                        </span>
                        <span
                          className="badge blue lighten-2 tooltipped"
                          data-tooltip="Gerar XML"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">link</i>
                          </a>
                        </span>
                        <span
                          className="badge blue darken-3 tooltipped"
                          data-tooltip="Solicitar documentação"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">person</i>
                          </a>
                        </span>
                        <span
                          className="badge white lighten-2 tooltipped"
                          data-tooltip="Adicionar nota"
                          data-position="top">
                          <a href="" className="black-text">
                            <i className="material-icons">
                              insert_drive_file
                            </i>
                          </a>
                        </span>
                        <span className="badge blue darken-3">
                          <a href="" className="white-text">
                            <i className="material-icons">
                              remove_red_eye
                            </i>
                          </a>
                        </span>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Não autorizar envio"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">thumb_up</i>
                          </a>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>284425</td>
                      <td>06/03/2018 às 15:52:44</td>
                      <td>
                        <a
                          href="#"
                          className="btn waves-effect white black-text tooltipped"
                          data-tooltip="Visualizar usuário"
                          data-position="top">
                          79702 / Eclair
                        </a>
                      </td>
                      <td>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Conta verificada"
                          data-position="top">
                          S
                        </span>
                      </td>
                      <td>19</td>
                      <td>US$ 138.75 138.12 + 0.63</td>
                      <td>
                        <span
                          className="badge blue lighten-2 tooltipped"
                          data-tooltip="Cartão de crédito"
                          data-position="top">
                          C
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge blue lighten-2 tooltipped"
                          data-tooltip="USPS priority"
                          data-position="top">
                          UP
                        </span>
                      </td>
                      <td>
                        <span className="badge green lighten-2">
                          Aprovado
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Não confirmar pacote"
                          data-position="top">
                          <a href="#" className="white-text">
                            S
                          </a>
                        </span>
                        <span
                          className="badge orange tooltipped"
                          data-tooltip="Confirmar/Autorizar envio"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">
                              file_upload
                            </i>
                          </a>
                        </span>
                      </td>
                      <td className="actions">
                        <span
                          className="badge orange tooltipped"
                          data-tooltip="Editar pacote"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">edit</i>
                          </a>
                        </span>
                        <span
                          className="badge white tooltipped"
                          data-tooltip="Ticket de interação com o suporte"
                          data-position="top">
                          <a href="" className="black-text">
                            <i className="material-icons">
                              confirmation_number
                            </i>
                          </a>
                        </span>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Enviar pacote"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">forward</i>
                          </a>
                        </span>
                        <span
                          className="badge blue lighten-2 tooltipped"
                          data-tooltip="Gerar XML"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">link</i>
                          </a>
                        </span>
                        <span
                          className="badge blue darken-3 tooltipped"
                          data-tooltip="Solicitar documentação"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">person</i>
                          </a>
                        </span>
                        <span
                          className="badge white lighten-2 tooltipped"
                          data-tooltip="Adicionar nota"
                          data-position="top">
                          <a href="" className="black-text">
                            <i className="material-icons">
                              insert_drive_file
                            </i>
                          </a>
                        </span>
                        <span className="badge blue darken-3">
                          <a href="" className="white-text">
                            <i className="material-icons">
                              remove_red_eye
                            </i>
                          </a>
                        </span>
                        <span
                          className="badge green lighten-2 tooltipped"
                          data-tooltip="Não autorizar envio"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">thumb_up</i>
                          </a>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AuthorizedPackages;
