import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Shortcuts extends Component {
  render() {
    return (
      <div id="dailyTasks">
        <h5>
          <i className="material-icons left">home</i> Atalhos
        </h5>
        <ul id="dailyTasksBadges" className="hide-on-med-and-down">
          <Link to="/estoque_rastreios" className="white-text">
            <li className="squaredLink red btn waves-effect">
              Rastreios
            </li>
          </Link>
          <Link to="/pacotes_aprovados">
            <li className="squaredLink btn white waves-effect black-text">
              Pct Aprovados
            </li>
          </Link>
          <Link
            to="/pgtos_pacotes?processando"
            className="white-text">
            <li className="squaredLink green lighten-2 btn waves-effect">
              Pct Pagamentos
            </li>
          </Link>
          <Link to="/dolar" className="white-text">
            <li className="squaredLink indigo darken-3 btn waves-effect">
              Dólar
            </li>
          </Link>
          <li className="squaredLink blue lighten-2 btn waves-effect">
            <a href="#" className="white-text">
              Cartões
            </a>
          </li>
          <Link to="/documentos?nao_validados">
            <li className="squaredLink orange btn waves-effect white-text">
              Documentos
            </li>
          </Link>
          <Link to="/pgtos_avulsos?pendente">
            <li className="squaredLink btn white waves-effect black-text">
              Pgto Avulsos
            </li>
          </Link>
        </ul>
      </div>
    );
  }
}

export default Shortcuts;
