import React, {Component} from 'react';

import queryString from 'query-string';

import Header from './Header';
import Sidebar from './Sidebar';
import Shortcuts from './Shortcuts';
import Breadcrumbs from './Breadcrumbs';
import {Link} from 'react-router-dom';

import Plugins from '../utils/Plugins';

import './packagespayment.css';
import '../assets/css/dataTables.material.min.css';

class PacketsPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancelled: false,
      isProcessing: false,
      isRefused: false,
      isApproved: false,
      isAll: false,
    };

    this.props.history.listen((location, action) => {
      this.parseStateFromLocation(location.search);
    });
  }

  parseStateFromLocation = locationSearch => {
    const values = queryString.parse(locationSearch);

    this.setState({
      isCancelled: typeof values['cancelados'] !== 'undefined',
      isProcessing: typeof values['processando'] !== 'undefined',
      isRefused: typeof values['recusados'] !== 'undefined',
      isApproved: typeof values['aprovados'] !== 'undefined',
      isAll: typeof values['todos'] !== 'undefined',
    });
  };

  componentDidMount() {
    this.parseStateFromLocation(this.props.location.search);
  }

  componentDidUpdate() {
    //chamada para inicializar o DataTables
    Plugins.initDataTables();
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <Sidebar />

        <div id="main">
          <div className="row">
            <div className="col s12">
              <div className="container">
                <Shortcuts />
                <Breadcrumbs
                  crumbs={[
                    {label: 'Dashboard', url: '/dashboard'},
                    {label: 'Pacotes', url: '/pacotes'},
                    {
                      label: 'Pagamentos com cartões',
                      url: '/pgtos_pacotes',
                    },
                  ]}
                />
                <div id="packetFilters">
                  <Link to="/pgtos_pacotes?processando">
                    <button className="btn orange white-text">
                      <i className="material-icons left">
                        rotate_right
                      </i>
                      Processando
                    </button>
                  </Link>
                  <Link to="/pgtos_pacotes?cancelados">
                    <button className="btn red white-text">
                      <i className="material-icons left">
                        thumb_down
                      </i>
                      Cancelados
                    </button>
                  </Link>
                  <Link to="/pgtos_pacotes?recusados">
                    <button className="btn white-text indigo darken-3">
                      <i className="material-icons left">
                        thumb_down
                      </i>
                      Recusados
                    </button>
                  </Link>
                  <Link to="/pgtos_pacotes?aprovados">
                    <button className="btn white-text green lighten-2">
                      <i className="material-icons left">thumb_up</i>
                      Aprovados
                    </button>
                  </Link>
                  <Link to="/pgtos_pacotes?todos">
                    <button className="btn white-text blue lighten-2">
                      <i className="material-icons left">list</i>
                      Todos
                    </button>
                  </Link>
                </div>
                <div className="divider" />
                <h5 className="tituloTables">
                  {this.state.isProcessing
                    ? 'Pagamentos processando cartões'
                    : null}
                  {this.state.isCancelled
                    ? 'Pagamentos cancelados cartões'
                    : null}
                  {this.state.isRefused
                    ? 'Pagamentos recusados cartões'
                    : null}
                  {this.state.isApproved
                    ? 'Pagamentos aprovados cartões'
                    : null}
                  {this.state.isAll
                    ? 'Todos os pagamentos cartões'
                    : null}
                </h5>
                <div className="divider" />

                {this.state.isProcessing ? (
                  <h5 className="red-text text-accent-2 nenhumRegistroEncontrado">
                    <i className="material-icons left">warning</i>
                    Nenhum registro encontrado no sistema
                  </h5>
                ) : null}

                <span>
                  {this.state.isCancelled ? (
                    <div>
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>User</th>
                            <th>Pacote</th>
                            <th>AV</th>
                            <th>SC</th>
                            <th>CV</th>
                            <th>Nº Cartão</th>
                            <th>Val</th>
                            <th>Nome cartão</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge light-blue accent-3 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                AV
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>539029******8566</td>
                            <td>0818</td>
                            <td>CREDICARD</td>
                            <td>
                              <span className="orange-text">
                                86.41
                              </span>
                            </td>
                            <td>
                              <span className="badge red">
                                Cancelado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge light-blue accent-3 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                AV
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>539029******8566</td>
                            <td>0818</td>
                            <td>CREDICARD</td>
                            <td>
                              <span className="orange-text">
                                86.41
                              </span>
                            </td>
                            <td>
                              <span className="badge red">
                                Cancelado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge light-blue accent-3 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                AV
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>539029******8566</td>
                            <td>0818</td>
                            <td>CREDICARD</td>
                            <td>
                              <span className="orange-text">
                                86.41
                              </span>
                            </td>
                            <td>
                              <span className="badge red">
                                Cancelado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </span>

                <span>
                  {this.state.isRefused ? (
                    <div>
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>User</th>
                            <th>Pacote</th>
                            <th>AV</th>
                            <th>SC</th>
                            <th>CV</th>
                            <th>Nº Cartão</th>
                            <th>Val</th>
                            <th>Nome cartão</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge light-blue accent-3 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                AV
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>539029******8566</td>
                            <td>0818</td>
                            <td>CREDICARD</td>
                            <td>
                              <span className="orange-text">
                                86.41
                              </span>
                            </td>
                            <td>
                              <span className="badge red">
                                Recusado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge light-blue accent-3 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                AV
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>539029******8566</td>
                            <td>0818</td>
                            <td>CREDICARD</td>
                            <td>
                              <span className="orange-text">
                                86.41
                              </span>
                            </td>
                            <td>
                              <span className="badge red">
                                Recusado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge light-blue accent-3 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                AV
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>539029******8566</td>
                            <td>0818</td>
                            <td>CREDICARD</td>
                            <td>
                              <span className="orange-text">
                                86.41
                              </span>
                            </td>
                            <td>
                              <span className="badge red">
                                Recusado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </span>

                <span>
                  {this.state.isApproved ? (
                    <div>
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>User</th>
                            <th>Pacote</th>
                            <th>AV</th>
                            <th>SC</th>
                            <th>CV</th>
                            <th>Nº Cartão</th>
                            <th>Val</th>
                            <th>Nome cartão</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge light-blue accent-3 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                AV
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>539029******8566</td>
                            <td>0818</td>
                            <td>CREDICARD</td>
                            <td>
                              <span className="orange-text">
                                86.41
                              </span>
                            </td>
                            <td>
                              <span className="badge green lighten-2">
                                Aprovado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge light-blue accent-3 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                AV
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>539029******8566</td>
                            <td>0818</td>
                            <td>CREDICARD</td>
                            <td>
                              <span className="orange-text">
                                86.41
                              </span>
                            </td>
                            <td>
                              <span className="badge green lighten-2">
                                Aprovado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge light-blue accent-3 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                AV
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>539029******8566</td>
                            <td>0818</td>
                            <td>CREDICARD</td>
                            <td>
                              <span className="orange-text">
                                86.41
                              </span>
                            </td>
                            <td>
                              <span className="badge green lighten-2">
                                Aprovado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </span>

                <span>
                  {this.state.isAll ? (
                    <div>
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>User</th>
                            <th>Pacote</th>
                            <th>AV</th>
                            <th>SC</th>
                            <th>CV</th>
                            <th>Nº Cartão</th>
                            <th>Val</th>
                            <th>Nome cartão</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge light-blue accent-3 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                AV
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>539029******8566</td>
                            <td>0818</td>
                            <td>CREDICARD</td>
                            <td>
                              <span className="orange-text">
                                86.41
                              </span>
                            </td>
                            <td>
                              <span className="badge green lighten-2">
                                Aprovado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge light-blue accent-3 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                AV
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>539029******8566</td>
                            <td>0818</td>
                            <td>CREDICARD</td>
                            <td>
                              <span className="orange-text">
                                86.41
                              </span>
                            </td>
                            <td>
                              <span className="badge green lighten-2">
                                Aprovado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge light-blue accent-3 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                AV
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>539029******8566</td>
                            <td>0818</td>
                            <td>CREDICARD</td>
                            <td>
                              <span className="orange-text">
                                86.41
                              </span>
                            </td>
                            <td>
                              <span className="badge green lighten-2">
                                Aprovado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </span>

                <span>
                  {this.state.isAll ? (
                    <div id="detailedSearchPayments">
                      <h5>Pesquisa detalhada</h5>
                      <div className="divider" />
                      <form onSubmit={this.detailedSearchSubmit}>
                        <div className="row">
                          <div className="input-field col s2">
                            <label htmlFor="code">Código</label>
                            <input name="code" type="text" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s2">
                            <label htmlFor="user_id">ID User</label>
                            <input name="user_id" type="text" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s2">
                            <label htmlFor="date">Data</label>
                            <input
                              name="date"
                              className="datepicker"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s2">
                            <label htmlFor="track_code">
                              Rastreio
                            </label>
                            <input name="track_code" type="text" />
                          </div>
                        </div>
                        <div className="divider" />
                        <div className="" id="formActionsWrapper">
                          <button
                            className="btn white black-text waves-effect"
                            onClick={e => {
                              e.preventDefault();
                              this.props.history.goBack();
                            }}>
                            <i className="material-icons left">
                              refresh
                            </i>
                            Voltar
                          </button>
                          <button
                            type="submit"
                            className="btn green lighten-2 waves-effect">
                            <i className="material-icons left">
                              search
                            </i>
                            Pesquisar
                          </button>
                        </div>
                      </form>
                    </div>
                  ) : null}
                </span>

                <h5 className="tituloTables">
                  {this.state.isProcessing
                    ? 'Pagamentos processando western'
                    : null}
                  {this.state.isCancelled
                    ? 'Pagamentos cancelados western'
                    : null}
                  {this.state.isRefused
                    ? 'Pagamentos recusados western'
                    : null}
                  {this.state.isApproved
                    ? 'Pagamentos aprovados western'
                    : null}
                  {this.state.isAll
                    ? 'Todos os pagamentos western'
                    : null}
                </h5>
                <div className="divider" />
                {this.state.isProcessing ? (
                  <h5 className="red-text text-accent-2 nenhumRegistroEncontrado">
                    <i className="material-icons left">warning</i>
                    Nenhum registro encontrado no sistema
                  </h5>
                ) : null}

                <span>
                  {this.state.isCancelled ? (
                    <div>
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>User</th>
                            <th>Pacote</th>
                            <th>AV</th>
                            <th>CV</th>
                            <th>Nome</th>
                            <th>MTCN</th>
                            <th>Valor</th>
                            <th>Anexo</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>SERGIO N PINHEIRO</td>
                            <td>89808402384082304</td>
                            <td>
                              <span className="orange-text">
                                100.01
                              </span>
                            </td>
                            <td>
                              <a
                                href=""
                                className="tooltipped"
                                data-tooltip="Comprovante de pagamento"
                                data-position="top">
                                <i className="black-text fas fa-barcode fa-2x" />
                              </a>
                            </td>
                            <td>
                              <span className="badge red">
                                Cancelado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </span>

                <span>
                  {this.state.isRefused ? (
                    <div>
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>User</th>
                            <th>Pacote</th>
                            <th>AV</th>
                            <th>CV</th>
                            <th>Nome</th>
                            <th>MTCN</th>
                            <th>Valor</th>
                            <th>Anexo</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>SERGIO N PINHEIRO</td>
                            <td>89808402384082304</td>
                            <td>
                              <span className="orange-text">
                                100.01
                              </span>
                            </td>
                            <td>
                              <a
                                href=""
                                className="tooltipped"
                                data-tooltip="Comprovante de pagamento"
                                data-position="top">
                                <i className="black-text fas fa-barcode fa-2x" />
                              </a>
                            </td>
                            <td>
                              <span className="badge red">
                                Recusado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </span>

                <span>
                  {this.state.isApproved ? (
                    <div>
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>User</th>
                            <th>Pacote</th>
                            <th>AV</th>
                            <th>CV</th>
                            <th>Nome</th>
                            <th>MTCN</th>
                            <th>Valor</th>
                            <th>Anexo</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>SERGIO N PINHEIRO</td>
                            <td>89808402384082304</td>
                            <td>
                              <span className="orange-text">
                                100.01
                              </span>
                            </td>
                            <td>
                              <a
                                href=""
                                className="tooltipped"
                                data-tooltip="Comprovante de pagamento"
                                data-position="top">
                                <i className="black-text fas fa-barcode fa-2x" />
                              </a>
                            </td>
                            <td>
                              <span className="badge green lighten-2">
                                Aprovado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </span>

                <span>
                  {this.state.isAll ? (
                    <div>
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>User</th>
                            <th>Pacote</th>
                            <th>AV</th>
                            <th>CV</th>
                            <th>Nome</th>
                            <th>MTCN</th>
                            <th>Valor</th>
                            <th>Anexo</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>SERGIO N PINHEIRO</td>
                            <td>89808402384082304</td>
                            <td>
                              <span className="orange-text">
                                100.01
                              </span>
                            </td>
                            <td>
                              <a
                                href=""
                                className="tooltipped"
                                data-tooltip="Comprovante de pagamento"
                                data-position="top">
                                <i className="black-text fas fa-barcode fa-2x" />
                              </a>
                            </td>
                            <td>
                              <span className="badge green lighten-2">
                                Aprovado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </span>

                <h5 className="tituloTables">
                  {this.state.isProcessing
                    ? 'Pagamentos processando depósito'
                    : null}
                  {this.state.isCancelled
                    ? 'Pagamentos cancelados depósito'
                    : null}
                  {this.state.isRefused
                    ? 'Pagamentos recusados depósito'
                    : null}
                  {this.state.isApproved
                    ? 'Pagamentos aprovados depósito'
                    : null}
                  {this.state.isAll
                    ? 'Todos os pagamentos depósito'
                    : null}
                </h5>
                <div className="divider" />

                <div>
                  {this.state.isProcessing ? (
                    <div className="row">
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>User</th>
                            <th>Pacote</th>
                            <th>AV</th>
                            <th>CV</th>
                            <th>Banco</th>
                            <th>Valor</th>
                            <th>Anexo</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>Itaú</td>
                            <td>
                              <span className="orange-text">
                                R$ 337,91
                              </span>
                            </td>
                            <td>
                              <a
                                href=""
                                className="tooltipped"
                                data-tooltip="Comprovante de pagamento"
                                data-position="top">
                                <i className="black-text fas fa-barcode fa-2x" />
                              </a>
                            </td>
                            <td>
                              <span className="badge blue lighten-2">
                                Processando
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>

                <span>
                  {this.state.isCancelled ? (
                    <div>
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>User</th>
                            <th>Pacote</th>
                            <th>AV</th>
                            <th>CV</th>
                            <th>Banco</th>
                            <th>Valor</th>
                            <th>Anexo</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>Itaú</td>
                            <td>
                              <span className="orange-text">
                                R$ 337,91
                              </span>
                            </td>
                            <td>
                              <a
                                href=""
                                className="tooltipped"
                                data-tooltip="Comprovante de pagamento"
                                data-position="top">
                                <i className="black-text fas fa-barcode fa-2x" />
                              </a>
                            </td>
                            <td>
                              <span className="badge red">
                                Cancelado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </span>

                <span>
                  {this.state.isRefused ? (
                    <div>
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>User</th>
                            <th>Pacote</th>
                            <th>AV</th>
                            <th>CV</th>
                            <th>Banco</th>
                            <th>Valor</th>
                            <th>Anexo</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>Itaú</td>
                            <td>
                              <span className="orange-text">
                                R$ 337,91
                              </span>
                            </td>
                            <td>
                              <a
                                href=""
                                className="tooltipped"
                                data-tooltip="Comprovante de pagamento"
                                data-position="top">
                                <i className="black-text fas fa-barcode fa-2x" />
                              </a>
                            </td>
                            <td>
                              <span className="badge red">
                                Recusado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </span>

                <span>
                  {this.state.isApproved ? (
                    <div>
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>User</th>
                            <th>Pacote</th>
                            <th>AV</th>
                            <th>CV</th>
                            <th>Banco</th>
                            <th>Valor</th>
                            <th>Anexo</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>Itaú</td>
                            <td>
                              <span className="orange-text">
                                R$ 337,91
                              </span>
                            </td>
                            <td>
                              <a
                                href=""
                                className="tooltipped"
                                data-tooltip="Comprovante de pagamento"
                                data-position="top">
                                <i className="black-text fas fa-barcode fa-2x" />
                              </a>
                            </td>
                            <td>
                              <span className="badge green lighten-2">
                                Aprovado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </span>

                <span>
                  {this.state.isAll ? (
                    <div>
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>User</th>
                            <th>Pacote</th>
                            <th>AV</th>
                            <th>CV</th>
                            <th>Banco</th>
                            <th>Valor</th>
                            <th>Anexo</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect indigo darken-3 white-text">
                                79702
                              </a>
                            </td>
                            <td>60257</td>
                            <td>
                              <span
                                className="badge white-text grey lighten-1 tooltipped"
                                data-tooltip="Sem avaliação - Cliente"
                                data-position="top">
                                SA
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge green lighten-2 tooltipped"
                                data-tooltip="Conta verificada"
                                data-position="top">
                                SIM
                              </span>
                            </td>
                            <td>Itaú</td>
                            <td>
                              <span className="orange-text">
                                R$ 337,91
                              </span>
                            </td>
                            <td>
                              <a
                                href=""
                                className="tooltipped"
                                data-tooltip="Comprovante de pagamento"
                                data-position="top">
                                <i className="black-text fas fa-barcode fa-2x" />
                              </a>
                            </td>
                            <td>
                              <span className="badge green lighten-2">
                                Aprovado
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge orange tooltipped"
                                data-tooltip="Editar pacote"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    edit
                                  </i>
                                </a>
                              </span>
                              <span
                                className="badge blue lighten-2 tooltipped"
                                data-tooltip="Editar Status"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    info
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PacketsPayment;
