import React, {Component} from 'react';

import Header from './Header';
import Sidebar from './Sidebar';
import Shortcuts from './Shortcuts';
import Breadcrumbs from './Breadcrumbs';

import '../assets/css/custom/custom.css';

class StockTrackings extends Component {
  state = {
    showSearch: false,
    userIdFiltered: false,
    emptySearchResults: false,
    id_user_filter: '',
  };

  detailedSearchSubmit = e => {
    e.preventDefault();

    this.setState({showSearch: true, emptySearchResults: false});
  };

  filterByUserSubmit = e => {
    e.preventDefault();

    if (this.state.id_user_filter == '') {
      this.setState({
        emptySearchResults: true,
        userIdFiltered: false,
      });
    } else {
      this.setState({
        emptySearchResults: false,
        userIdFiltered: true,
      });
    }
  };

  onChange = e => this.setState({[e.target.name]: e.target.value});

  render() {
    const {
      showSearch,
      userIdFiltered,
      emptySearchResults,
    } = this.state;

    return (
      <React.Fragment>
        <Header />

        <Sidebar />

        <div id="main">
          <div className="row">
            <div className="col s12">
              <div className="container">
                <Shortcuts />

                <Breadcrumbs
                  crumbs={[
                    {label: 'Dashboard', url: '/dashboard'},
                    {
                      label: 'Estoque Rastreios',
                      url: '/estoque_rastreios',
                    },
                  ]}
                />
              </div>
            </div>
            <div className="col s3 offset-s9">
              <div className="container">
                <form
                  className="flex align-center"
                  id="form-filter"
                  onSubmit={this.filterByUserSubmit}>
                  <div className="col s4 push-s2">
                    <input
                      type="text"
                      name="id_user_filter"
                      placeholder="ID User"
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="col s2">
                    <button
                      type="submit"
                      className="btn right waves-effect">
                      <i className="material-icons">filter_list</i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col s12">
              <div className="container">
                {(showSearch || userIdFiltered) &&
                !emptySearchResults ? (
                  <table
                    id="searchResultsTable"
                    className="striped highlight">
                    <thead>
                      <tr>
                        <th>Código</th>
                        <th>ID / Usuário</th>
                        <th>Data</th>
                        <th>Rastreio</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>284425</td>
                        <td>
                          <a
                            href="#"
                            className="btn waves-effect white black-text">
                            79702 / Eclair
                          </a>
                        </td>
                        <td>06/03/2018 às 15:52:44</td>
                        <td>
                          <a
                            href="#"
                            className="btn waves-effect white black-text">
                            <i className="material-icons left">
                              search
                            </i>
                            420328249405509699938250693003
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>284425</td>
                        <td>
                          <a
                            href="#"
                            className="btn waves-effect white black-text">
                            79702 / Eclair
                          </a>
                        </td>
                        <td>06/03/2018 às 15:52:44</td>
                        <td>
                          <a
                            href="#"
                            className="btn waves-effect white black-text">
                            <i className="material-icons left">
                              search
                            </i>
                            420328249405509699938250693003
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>284425</td>
                        <td>
                          <a
                            href="#"
                            className="btn waves-effect white black-text">
                            79702 / Eclair
                          </a>
                        </td>
                        <td>06/03/2018 às 15:52:44</td>
                        <td>
                          <a
                            href="#"
                            className="btn waves-effect white black-text">
                            <i className="material-icons left">
                              search
                            </i>
                            420328249405509699938250693003
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>284425</td>
                        <td>
                          <a
                            href="#"
                            className="btn waves-effect white black-text">
                            79702 / Eclair
                          </a>
                        </td>
                        <td>06/03/2018 às 15:52:44</td>
                        <td>
                          <a
                            href="#"
                            className="btn waves-effect white black-text">
                            <i className="material-icons left">
                              search
                            </i>
                            420328249405509699938250693003
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : null}

                {emptySearchResults ? (
                  <h5
                    id="nenhumRegistroEncontrado"
                    className="red-text text-accent-2">
                    <i className="material-icons left">warning</i>
                    Nenhum registro encontrado no sistema
                  </h5>
                ) : null}

                <h5>Pesquisa detalhada</h5>
                <div className="divider" />
                <form onSubmit={this.detailedSearchSubmit}>
                  <div className="row">
                    <div className="input-field col s2">
                      <label htmlFor="code">Código</label>
                      <input name="code" type="text" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s2">
                      <label htmlFor="user_id">ID User</label>
                      <input name="user_id" type="text" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s2">
                      <label htmlFor="date">Data</label>
                      <input
                        name="date"
                        className="datepicker"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s2">
                      <label htmlFor="track_code">Rastreio</label>
                      <input name="track_code" type="text" />
                    </div>
                  </div>
                  <div className="divider" />
                  <div className="" id="formActionsWrapper">
                    <button
                      className="btn white black-text waves-effect"
                      onClick={e => {
                        e.preventDefault();
                        this.props.history.goBack();
                      }}>
                      <i className="material-icons left">refresh</i>
                      Voltar
                    </button>
                    <button
                      type="submit"
                      className="btn green lighten-2 waves-effect">
                      <i className="material-icons left">search</i>
                      Pesquisar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StockTrackings;
