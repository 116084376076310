import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import Login from './containers/Login';
import Dashboard from './containers/Dashboard';
import StockTrackings from './containers/StockTrackings';
import Packages from './containers/Packages';
import PackagesPayment from './containers/PackagesPayment';
import Dolar from './containers/Dolar';
import Documents from './containers/Documents';

import Api from './services';
import {Provider} from './context';
import './assets/custom.css';
import Token from './utils/Token';

import Plugin from './utils/Plugins';
import DetachedPayments from './containers/DetachedPayments';
import PackagesDepositPayment from './containers/PackagesDepositPayment';
import Attachments from './containers/Attachments';
import AuthorizedPackages from './containers/AuthorizedPackages';
import AntiFraud from './containers/AntiFraud';
import PendingPackages from './containers/PendingPackages';
import SentPackages from './containers/SentPackages';
import HiddenPackages from './containers/HiddenPackages';
import DeletedPackages from './containers/DeletedPackages';
import RenewedPackages from './containers/RenewedPackages';

/*import './assets/js/vendors.min';*/
/*import './assets/js/perfect-scrollbar';
import './assets/js/plugins';*/

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: false,
    };
  }

  componentWillMount() {
    const token = Token.get();

    if (token) {
      this.setState({token: !!token});
    }
  }

  componentDidMount() {
    new Plugin();
    M.AutoInit();
  }

  componentDidUpdate() {
    new Plugin();
    //M.AutoInit();
  }

  validLogin = async (user, pass) => {
    if (!user || !pass) {
      return alert('Usuário ou senha não informados.');
    }

    const token = await Api.post('validLogin', {user, pass});
    if (token && token.status === 'error') {
      alert(token.message);
      return false;
    }
    this.setState({token: !!token});
    return true;
  };

  render() {
    const {token} = this.state;

    return (
      <Provider>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route
              exact
              path="/estoque_rastreios"
              component={StockTrackings}
            />
            <Route
              exact
              path="/pacotes_aprovados"
              component={Packages}
            />
            <Route
              exact
              path="/pgtos_pacotes"
              component={PackagesPayment}
            />
            <Route
              exact
              path="/pgtos_pacotes?processando"
              component={PackagesPayment}
            />
            <Route
              exact
              path="/pacotes_pendentes"
              component={PendingPackages}
            />
            <Route
              exact
              path="/pgtos_pacotes?cancelados"
              component={PackagesPayment}
            />
            <Route
              exact
              path="/pacotes_enviados"
              component={SentPackages}
            />
            <Route
              exact
              path="/pacotes_ocultos"
              component={HiddenPackages}
            />
            <Route
              exact
              path="/pacotes_deletados"
              component={DeletedPackages}
            />
            <Route
              exact
              path="/pacotes_renovados"
              component={RenewedPackages}
            />
            <Route exact path="/dolar" component={Dolar} />
            <Route exact path="/documentos" component={Documents} />
            <Route
              exact
              path="/pgtos_avulsos"
              component={DetachedPayments}
            />
            <Route
              exact
              path="/pgtos_pacotes_deposito"
              component={PackagesDepositPayment}
            />
            <Route
              exact
              path="/anexos_avulsos"
              component={Attachments}
            />
            <Route
              exact
              path="/pacotes_autorizados"
              component={AuthorizedPackages}
            />
            <Route exact path="/anti_fraud" component={AntiFraud} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
