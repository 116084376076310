import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import logo from '../../assets/images/logo_box_white.png';

import M from 'materialize-css';

import './style.css';

class Sidebar extends Component {
  componentWillMount() {
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.collapsible');
      var instances = M.Collapsible.init(elems, null);
    });
    /*let elemst = document.querySelectorAll('.collapsible');
    let instances = M.Collapsible.init(elemst, null);*/
  }

  componentDidMount() {
    var elems = document.querySelectorAll('.collapsible');
    var instances = M.Collapsible.init(elems, null);
  }

  render() {
    return (
      <aside className="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-active-rounded">
        <div className="brand-sidebar">
          <h1 className="logo-wrapper">
            <Link className="brand-logo darken-1" to="/dashboard">
              <span className="logo-text hide-on-med-and-down">
                <img
                  id="logoTopo"
                  width="150"
                  height="100"
                  src={logo}
                />
              </span>
            </Link>
            <a className="navbar-toggler" href="#">
              <i className="material-icons">radio_button_checked</i>
            </a>
          </h1>
        </div>
        <ul
          className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow"
          id="slide-out"
          data-menu="menu-navigation"
          data-collapsible="accordion">
          <li className="bold active">
            <a
              className="collapsible-header waves-effect waves-cyan "
              href="#">
              <i className="material-icons">group</i>
              <span className="menu-title" data-i18n="">
                Usuários
              </span>
            </a>
            <div className="collapsible-body">
              <ul
                className="collapsible collapsible-sub"
                data-collapsible="accordion">
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Listar</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Ranking de Envios</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Relatório</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Log</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Documentos</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="bold">
            <a
              className="collapsible-header waves-effect waves-cyan "
              href="#">
              <i className="material-icons">storage</i>
              <span className="menu-title" data-i18n="">
                Estoque
              </span>
            </a>
            <div className="collapsible-body">
              <ul
                className="collapsible collapsible-sub"
                data-collapsible="accordion">
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Produtos</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Estoque usuário</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Estoque expirado</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Estoque oculto</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Estoque deletado</span>
                  </a>
                </li>
                <li>
                  <Link
                    className="collapsible-body"
                    to="/estoque_rastreios"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Rastreios</span>
                  </Link>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Armazenamentos</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Setores</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Controle entrada</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="bold">
            <a
              className="collapsible-header waves-effect waves-cyan "
              href="#">
              <i className="material-icons">apps</i>
              <span className="menu-title" data-i18n="">
                Pacotes
              </span>
            </a>
            <div className="collapsible-body">
              <ul
                className="collapsible collapsible-sub"
                data-collapsible="accordion">
                <li>
                  <Link
                    className="collapsible-body"
                    to="/pacotes_pendentes"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Pendentes</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="collapsible-body"
                    to="/pacotes_aprovados"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Aprovados</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="collapsible-body"
                    to="/pacotes_enviados"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Enviados</span>
                  </Link>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Expirados</span>
                  </a>
                </li>
                <li>
                  <Link
                    className="collapsible-body"
                    to="/pacotes_ocultos"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Ocultos</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="collapsible-body"
                    to="/pacotes_renovados"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Renovados</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="collapsible-body"
                    to="/pacotes_deletados"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Deletados</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="collapsible-body"
                    to="/pgtos_pacotes?processando"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Pgtos Pacotes</span>
                  </Link>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Pgtos Cartões</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Pgtos Western Union</span>
                  </a>
                </li>
                <li>
                  <Link
                    className="collapsible-body"
                    to="/pgtos_pacotes_deposito"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Pgtos Depósito Bancário</span>
                  </Link>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Solicitação de documento</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Controle label</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="bold">
            <a
              className="collapsible-header waves-effect waves-cyan "
              href="#">
              <i className="material-icons">payment</i>
              <span className="menu-title" data-i18n="">
                Cartões
              </span>
            </a>
            <div className="collapsible-body">
              <ul
                className="collapsible collapsible-sub"
                data-collapsible="accordion">
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Listar</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="bold">
            <a
              className="collapsible-header waves-effect waves-cyan "
              href="#">
              <i className="material-icons">label</i>
              <span className="menu-title" data-i18n="">
                Diversos
              </span>
            </a>
            <div className="collapsible-body">
              <ul
                className="collapsible collapsible-sub"
                data-collapsible="accordion">
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Onde Comprar</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Onde comprar categorias</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Ofertas</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Notícias</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Avisos</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Preços</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Créditos</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Pontos</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Pontos dependentes</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Pontos aprovados</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Pontos ADM</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Leilão</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Sorteios</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="bold">
            <a
              className="collapsible-header waves-effect waves-cyan "
              href="#">
              <i className="material-icons">attach_money</i>
              <span className="menu-title" data-i18n="">
                Administrativo
              </span>
            </a>
            <div className="collapsible-body">
              <ul
                className="collapsible collapsible-sub"
                data-collapsible="accordion">
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Financeiro</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Funcionários</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="bold">
            <a
              className="collapsible-header waves-effect waves-cyan "
              href="#">
              <i className="material-icons">build</i>
              <span className="menu-title" data-i18n="">
                Manutenção
              </span>
            </a>
            <div className="collapsible-body">
              <ul
                className="collapsible collapsible-sub"
                data-collapsible="accordion">
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Usuários box</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Dólar</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Países</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Idiomas</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Vídeos</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Mensagem inicial</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Perguntas</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Perguntas tickets</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Testemunhal</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Travar funções</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Editar XML</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Value XML</span>
                  </a>
                </li>
                <li>
                  <a
                    className="collapsible-body"
                    href="#"
                    data-i18n="">
                    <i className="material-icons">
                      radio_button_unchecked
                    </i>
                    <span>Declaração proibida</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </aside>
    );
  }
}

export default Sidebar;
