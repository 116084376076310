import React, {Component} from 'react';

import queryString from 'query-string';

import SentPackagesAPI from '../services/SentPackages';

import Header from './Header';
import Sidebar from './Sidebar';
import Shortcuts from './Shortcuts';
import Breadcrumbs from './Breadcrumbs';
import {Link} from 'react-router-dom';

import Helper from '../utils/Helper';

import Plugins from '../utils/Plugins';

import '../assets/css/dataTables.material.min.css';

import '../style.css';

class SentPackages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPackages: false,
      isPrintedLabels: false,
      isNotPrintedLabels: false,
      sentCnt: 0,
      printedLabelsCnt: 0,
      notPrintedLabelsCnt: 0,
      code: null,
      user_id: null,
      date: null,
    };

    this.props.history.listen((location, action) => {
      this.parseStateFromLocation(location.search);
    });
  }

  parseStateFromLocation = locationSearch => {
    const values = queryString.parse(locationSearch);

    this.setState(
      {
        isPackages:
          typeof values['pacotes'] !== 'undefined' ||
          (Object.keys(values).length === 0 &&
            typeof values === 'object'),
        isPrintedLabels: typeof values['impressos'] !== 'undefined',
        isNotPrintedLabels:
          typeof values['naoimpressos'] !== 'undefined',
      },
      () => {
        this.updatePackages();
      },
    );
  };

  async updatePackages() {
    Plugins.destroyTables();
    let packages = null;

    if (this.state.isPackages) {
      packages = await SentPackagesAPI.getPackages();
    } else if (this.state.isPrintedLabels) {
      packages = await SentPackagesAPI.getPrintedLabelsPackages();
    } else if (this.state.isNotPrintedLabels) {
      packages = await SentPackagesAPI.getNotPrintedLabelsPackages();
    }

    this.setState({packages}, () => {
      Plugins.initDataTables();
      M.AutoInit();
    });
  }

  async componentDidMount() {
    this.parseStateFromLocation(this.props.location.search);

    let sentCnt = await SentPackagesAPI.getPackagesCount();
    let printedLabelsCnt = await SentPackagesAPI.getPrintedLabelsPackagesCount();
    let notPrintedLabelsCnt = await SentPackagesAPI.getNotPrintedLabelsPackagesCount();

    this.setState({
      sentCnt,
      printedLabelsCnt,
      notPrintedLabelsCnt,
    });
  }

  onInputChange = e => {
    this.setState({[e.target.name]: e.target.value});
  };

  detailedSearchSubmit = e => {
    e.preventDefault();
    console.log('detailedSearchSubmit');
  };

  render() {
    const {
      isPackages,
      isPrintedLabels,
      isNotPrintedLabels,
      packages,
      sentCnt,
      printedLabelsCnt,
      notPrintedLabelsCnt,
    } = this.state;

    let actionCrumb = {
      label: isPackages
        ? 'Pacotes enviados'
        : isPrintedLabels
        ? 'Pacotes com Labels Impressos'
        : isNotPrintedLabels
        ? 'Pacotes sem Labels Impressos'
        : null,
      url: '#',
    };

    return (
      <React.Fragment>
        <Header />

        <Sidebar />

        <div id="main">
          <div className="row">
            <div className="col s12">
              <div className="container">
                <Shortcuts />
                <Breadcrumbs
                  crumbs={[
                    {label: 'Dashboard', url: '/dashboard'},
                    {label: 'Pacotes', url: '/pacotes'},
                    actionCrumb,
                  ]}
                />
                <div id="packetFilters" className="flex align-center">
                  <Link to="/pacotes_enviados?pacotes">
                    <button className="btn white flex align-center black-text">
                      Pacotes
                      {sentCnt ? (
                        <span className="badge white-text grey darken-1">
                          {sentCnt}
                        </span>
                      ) : null}
                    </button>
                  </Link>
                  <Link to="/pacotes_enviados?impressos">
                    <button className="btn white-text flex align-center green lighten-2">
                      <i className="material-icons left">print</i>
                      Labels impressos
                      {printedLabelsCnt ? (
                        <span className="badge white-text grey darken-1">
                          {printedLabelsCnt}
                        </span>
                      ) : null}
                    </button>
                  </Link>
                  <Link to="/pacotes_enviados?naoimpressos">
                    <button className="btn red flex align-center white-text">
                      <i className="material-icons left">print</i>
                      Labels não impressos
                      {notPrintedLabelsCnt ? (
                        <span className="badge white-text grey darken-1">
                          {notPrintedLabelsCnt}
                        </span>
                      ) : null}
                    </button>
                  </Link>
                </div>
                <div className="divider" />

                <span>
                  <div>
                    <table
                      id=""
                      className="striped highlight datatable">
                      <thead>
                        <tr>
                          <th>Pacote</th>
                          <th>Enviado</th>
                          <th>ID/Usuário</th>
                          <th>Peso</th>
                          <th>Valor</th>
                          <th>Rastreio</th>
                          <th>Tipo</th>
                          <th>Status</th>
                          <th>OK?</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        {packages &&
                          packages.map(el => (
                            <tr key={el.id}>
                              <td>{el.packageId}</td>
                              <td>
                                {Helper.formatDate(
                                  'dd/MM/yyyy à\\s hh:MM:ss',
                                  el.date,
                                )}
                              </td>
                              <td>
                                <a
                                  href="#"
                                  className="btn waves-effect white darken-3 black-text nowrap">
                                  {el.user.id} / {el.user.name}
                                </a>
                              </td>
                              <td>{el.weight}</td>
                              <td>
                                <span className="nowrap">
                                  {Helper.formatCurrency(el.value)}
                                </span>
                              </td>
                              <td>
                                {el.trackings &&
                                  el.trackings.map(t => (
                                    <a
                                      key={t.id}
                                      className="btn white black-text">
                                      <i className="left material-icons">
                                        search
                                      </i>
                                      {t.id}
                                    </a>
                                  ))}
                              </td>
                              <td>
                                {el.type == 'fc' ? (
                                  <span
                                    className="badge blue lighten-2 tooltipped"
                                    data-tooltip="FIRST CLASS"
                                    data-position="top">
                                    FC
                                  </span>
                                ) : el.type == 'fp' ? (
                                  <span
                                    className="badge orange lighten-2 tooltipped"
                                    data-tooltip="FEDEX PRIORITY"
                                    data-position="top">
                                    FP
                                  </span>
                                ) : null}
                              </td>
                              <td>
                                {el.status.toLowerCase() ==
                                'enviado' ? (
                                  <span className="badge green lighten-2">
                                    ENVIADO
                                  </span>
                                ) : null}
                              </td>
                              <td>
                                {!el.confirmedPackage ? (
                                  <span className="badge green lighten-2">
                                    SIM
                                  </span>
                                ) : (
                                  <span className="badge red">
                                    NÃO
                                  </span>
                                )}
                              </td>
                              <td className="actions">
                                <span
                                  className="badge orange tooltipped"
                                  data-tooltip="Editar pacote"
                                  data-position="top">
                                  <a href="#" className="white-text">
                                    <i className="material-icons">
                                      edit
                                    </i>
                                  </a>
                                </span>
                                <span
                                  className="badge white tooltipped"
                                  data-tooltip="Ticket de interação com o suporte"
                                  data-position="top">
                                  <a href="" className="black-text">
                                    <i className="material-icons">
                                      confirmation_number
                                    </i>
                                  </a>
                                </span>
                                <span
                                  className="badge indigo darken-3 tooltipped modal-trigger"
                                  data-target={'modal-pkgdet'}
                                  data-tooltip="Ticket de interação com o suporte"
                                  data-position="top">
                                  <a href="" className="white-text">
                                    <i className="material-icons">
                                      remove_red_eye
                                    </i>
                                  </a>
                                </span>
                                <span
                                  class="badge green lighten-2 tooltipped"
                                  data-tooltip="Enviar pacote"
                                  data-position="top">
                                  <a href="" className="white-text">
                                    <i className="material-icons">
                                      forward
                                    </i>
                                  </a>
                                </span>
                                <span
                                  className="badge white lighten-2 tooltipped"
                                  data-tooltip="Adicionar nota"
                                  data-position="top">
                                  <a href="" className="black-text">
                                    <i className="material-icons">
                                      search
                                    </i>
                                  </a>
                                </span>
                                {isPrintedLabels ? (
                                  <React.Fragment>
                                    <span
                                      class="badge green lighten-2 tooltipped"
                                      data-tooltip="Baixar label"
                                      data-position="top">
                                      <a
                                        href=""
                                        className="white-text">
                                        <i className="material-icons">
                                          attach_file
                                        </i>
                                      </a>
                                    </span>
                                    <span
                                      class="badge green lighten-2 tooltipped"
                                      data-tooltip="Cancelar label"
                                      data-position="top">
                                      <a
                                        href=""
                                        className="white-text">
                                        <i className="material-icons">
                                          print
                                        </i>
                                      </a>
                                    </span>
                                  </React.Fragment>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </span>

                <span>
                  <div id="detailedSearchPayments">
                    <h5>Pesquisa detalhada</h5>
                    <div className="divider" />
                    <form onSubmit={this.detailedSearchSubmit}>
                      <div className="row">
                        <div className="input-field col s2">
                          <label htmlFor="code">Código</label>
                          <input
                            onChange={this.onInputChange}
                            name="code"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s2">
                          <label htmlFor="user_id">ID User</label>
                          <input
                            onChange={this.onInputChange}
                            name="user_id"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s2">
                          <label htmlFor="date">Data</label>
                          <input
                            onChange={this.onInputChange}
                            name="date"
                            className="datepicker"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s2">
                          <label htmlFor="track_code">Rastreio</label>
                          <input
                            onChange={this.onInputChange}
                            name="track_code"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="" id="formActionsWrapper">
                        <button
                          className="btn white black-text waves-effect"
                          onClick={e => {
                            e.preventDefault();
                            this.props.history.goBack();
                          }}>
                          <i className="material-icons left">
                            refresh
                          </i>
                          Voltar
                        </button>
                        <button
                          type="submit"
                          className="btn green lighten-2 waves-effect">
                          <i className="material-icons left">
                            search
                          </i>
                          Pesquisar
                        </button>
                      </div>
                    </form>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SentPackages;
