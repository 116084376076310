import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './app';

import './assets/css/themes/vertical-dark-menu-template/materialize.min.css';
import './assets/css/themes/vertical-dark-menu-template/style.min.css';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);

module.hot.accept();

function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('./service-worker.js', {scope: '/'})
      .then(() =>
        console.log('Service Worker registered successfully.'),
      )
      .catch(error =>
        console.log('Service Worker registration failed:', error),
      );
  }
}
registerServiceWorker();
