import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import './breadcrumb.css';

class Breadcrumbs extends Component {
  render() {
    const {crumbs} = this.props;

    return (
      <nav id="breadcrumb">
        <div className="nav-wrapper orange">
          <div className="col s12">
            {crumbs.map((crumb, i) => (
              <Link to={crumb.url} key={i} className="breadcrumb">
                {crumb.label}
              </Link>
            ))}
          </div>
        </div>
      </nav>
    );
  }
}

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array.isRequired,
};

export default Breadcrumbs;
