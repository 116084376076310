import axios from 'axios';
import Token from '../utils/Token';
import { URL_API } from '../config';

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  response => response,
  (error) => {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      Token.remove();
      location.reload();
    }
    return Promise.reject(error);
  },
);

const METODOS_POST = {
  validLogin: {
    url: 'login',
    response: 'token',
    addictFunction: (response) => {
      if (response.token) {
        Token.set(response.token);
        return response.token;
      }
    },
  },
};

const Api = {
  async getPackages() {
    try {
      const token = await Token.get();

      if (token) {
        const { data } = await axios.get(`${URL_API}/packages`, {
          headers: {
            token,
          },
        });

        return data;
      }
      return false;
    } catch (error) {
      return {
        status: 'error',
        message: error.request && error.request.response,
      };
    }
  },

  async getPackageDeclaration(id) {
    try {
      const token = await Token.get();
      if (token) {
        const { data } = await axios.get(
          `${URL_API}/packages/${id}/declaration/`,
          {
            headers: {
              token,
            },
          },
        );
        return data.declaration;
      }
      return false;
    } catch (error) {
      return {
        status: 'error',
        message: error.request && error.request.response,
      };
    }
  },

  async postPackageDeclaration(id, data) {
    try {
      const token = await Token.get();

      if (token) {
        const response = await axios.put(
          `${URL_API}/packages/${id}/declaration`,
          {
            dec: data,
          },
          {
            headers: {
              token,
              // 'Content-Type': 'application/x-www-form-urlencoded'
            },
          },
        );

        return response.data;
      }
      return false;
    } catch (error) {
      return {
        status: 'error',
        message: error.request && error.request.response,
      };
    }
  },

  async post(metodoName, param) {
    try {
      const metodo = METODOS_POST[metodoName];
      const { data } = await axios.post(
        `${URL_API}/${metodo.url}`,
        param,
      );

      if (metodo.addictFunction) {
        metodo.addictFunction(data);
      }

      return data[metodo.response];
    } catch (error) {
      return { status: 'error', message: error.request.response };
    }
  },
};

export default Api;
