import React, {Component} from 'react';

import queryString from 'query-string';

import Header from './Header';
import Sidebar from './Sidebar';
import Breadcrumbs from './Breadcrumbs';
import Shortcuts from './Shortcuts';

import PackagesAPI from '../services/Packages';

import Plugins from '../utils/Plugins';

import Helper from '../utils/Helper';

import {Modal} from 'react-materialize';

import {Link} from 'react-router-dom';

import '../style.css';

import '../assets/css/dataTables.material.min.css';

class PendingPackages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPending: true,
      isProcessing: false,
      isToPay: false,
      isCancelled: false,
      isRefused: false,
      isAll: false,
      pendingCnt: 0,
      processingCnt: 0,
      toPayCnt: 0,
      cancelledCnt: 0,
      refusedCnt: 0,
      allCnt: 0,
      packages: null,
    };

    this.props.history.listen((location, action) => {
      this.parseStateFromLocation(location.search);
    });
  }

  parseStateFromLocation = locationSearch => {
    const values = queryString.parse(locationSearch);

    const isPending =
      typeof values['pendentes'] !== 'undefined' ||
      (Object.keys(values).length === 0 &&
        typeof values === 'object');
    const isCancelled = typeof values['cancelados'] !== 'undefined';
    const isProcessing = typeof values['processando'] !== 'undefined';
    const isToPay = typeof values['pagar'] !== 'undefined';
    const isRefused = typeof values['recusados'] !== 'undefined';
    const isAll = typeof values['todos'] !== 'undefined';

    this.setState(
      {
        isPending,
        isCancelled,
        isProcessing,
        isToPay,
        isRefused,
        isAll,
      },
      () => {
        this.updatePackages();
      },
    );
  };

  async updatePackages() {
    Plugins.destroyTables();
    let packages = null;

    if (this.state.isPending) {
      packages = await PackagesAPI.getPendingPackages();
    } else if (this.state.isCancelled) {
      packages = await PackagesAPI.getCancelledPackages();
    } else if (this.state.isProcessing) {
      packages = await PackagesAPI.getProcessingPackages();
    } else if (this.state.isToPay) {
      packages = await PackagesAPI.getToPayPackages();
    } else if (this.state.isRefused) {
      packages = await PackagesAPI.getRefusedPackages();
    } else if (this.state.isAll) {
      packages = await PackagesAPI.getAllPackages();
    }

    this.setState({packages}, () => {
      Plugins.initDataTables();
      M.AutoInit();
    });
  }

  async componentDidMount() {
    this.parseStateFromLocation(this.props.location.search);

    let pendingCnt = await PackagesAPI.getPendingPackagesCount();
    let cancelledCnt = await PackagesAPI.getCancelledPackagesCount();
    let processingCnt = await PackagesAPI.getProcessingPackagesCount();
    let toPayCnt = await PackagesAPI.getToPayPackagesCount();
    let refusedCnt = await PackagesAPI.getRefusedPackagesCount();
    let allCnt = await PackagesAPI.getAllPackagesCount();

    this.setState({
      pendingCnt,
      cancelledCnt,
      processingCnt,
      toPayCnt,
      refusedCnt,
      allCnt,
    });
  }

  render() {
    const {
      packages,
      isPending,
      isCancelled,
      isToPay,
      isProcessing,
      isRefused,
      isAll,
      pendingCnt,
      cancelledCnt,
      toPayCnt,
      processingCnt,
      refusedCnt,
      allCnt,
    } = this.state;

    let actionCrumb = {
      label: isPending
        ? 'Pendentes'
        : isCancelled
        ? 'Cancelados'
        : isToPay
        ? 'A Pagar'
        : isProcessing
        ? 'Processando'
        : isRefused
        ? 'Recusados'
        : isAll
        ? 'Todos'
        : 'Pendentes',
      url: '#',
    };

    return (
      <React.Fragment>
        <Header />

        <Sidebar />

        <div id="main">
          <div className="row">
            <div className="col s12">
              <div className="container">
                <Shortcuts />
                <Breadcrumbs
                  crumbs={[
                    {label: 'Dashboard', url: '/dashboard'},
                    {
                      label: 'Pacotes pendentes',
                      url: '/pacotes_pendentes',
                    },
                    actionCrumb,
                  ]}
                />
                <div className="topFilters flex align-center">
                  <Link
                    to="/pacotes_pendentes?pendentes"
                    className="btn orange white-text flex align-center">
                    <i className="material-icons left">
                      rotate_right
                    </i>
                    Pendentes
                    {pendingCnt ? (
                      <span className="badge right white-text grey darken-1">
                        {pendingCnt}
                      </span>
                    ) : null}
                  </Link>
                  <Link
                    to="/pacotes_pendentes?processando"
                    className="btn green lighten-2 flex align-center">
                    <i className="material-icons left">
                      rotate_right
                    </i>
                    Processando
                    {processingCnt ? (
                      <span className="badge right white-text grey darken-1">
                        {processingCnt}
                      </span>
                    ) : null}
                  </Link>
                  <Link
                    to="/pacotes_pendentes?pagar"
                    className="btn white black-text flex align-center">
                    <i className="material-icons left">payment</i>A
                    Pagar
                    {toPayCnt ? (
                      <span className="badge right white-text grey darken-1">
                        {toPayCnt}
                      </span>
                    ) : null}
                  </Link>
                  <Link
                    to="/pacotes_pendentes?cancelados"
                    className="btn red flex align-center">
                    <i className="material-icons left">thumb_down</i>
                    Cancelados
                    {cancelledCnt ? (
                      <span className="badge right white-text grey darken-1">
                        {cancelledCnt}
                      </span>
                    ) : null}
                  </Link>
                  <Link
                    to="/pacotes_pendentes?recusados"
                    className="btn indigo darken-3 flex align-center">
                    <i className="material-icons left">thumb_down</i>
                    Recusados
                    {refusedCnt ? (
                      <span className="badge right white-text grey darken-1">
                        {refusedCnt}
                      </span>
                    ) : null}
                  </Link>
                  <Link
                    to="/pacotes_pendentes?todos"
                    className="btn blue lighten-2 flex align-center">
                    <i className="material-icons left">list</i>
                    Todos
                    {allCnt ? (
                      <span className="badge right white-text grey darken-1">
                        {allCnt}
                      </span>
                    ) : null}
                  </Link>
                </div>
                <div className="divider" />
              </div>
            </div>
            <div className="col s2 offset-s10">
              <div className="container">
                <div className="row">
                  <form className="col s12" />
                </div>
              </div>
            </div>
            <div className="col s12">
              <div className="container">
                <table
                  id="searchResultsTable"
                  className="striped highlight datatable">
                  <thead>
                    <tr>
                      <th>Pacote</th>
                      <th>Data</th>
                      <th>ID / Usuário</th>
                      <th>Prod</th>
                      <th>Peso</th>
                      <th>Valor</th>
                      <th>Pagamento</th>
                      <th>Tipo</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>

                  {packages && (
                    <tbody>
                      {packages.map(el => (
                        <tr key={el.id}>
                          <td>{el.id}</td>
                          <td>
                            {Helper.formatDate(
                              'dd/MM/yyyy à\\s hh:MM:ss',
                              el.date,
                            )}
                          </td>
                          <td>
                            <span
                              className="btn waves-effect white black-text tooltipped"
                              data-tooltip="Visualizar Usuário"
                              data-position="top">
                              {el.user.id} / {el.user.name}
                            </span>
                            <span
                              className="btn waves-effect white black-text tooltipped"
                              data-tooltip="Adicionar Nota"
                              data-position="top">
                              <i className="material-icons">note</i>
                            </span>
                          </td>
                          <td>
                            <span
                              data-target={'modal-prod' + el.id}
                              className="btn modal-trigger waves-effect indigo darken-3 white-text">
                              <i className="material-icons">link</i>
                            </span>
                          </td>
                          <td>{el.weight}</td>
                          <td>{Helper.formatCurrency(el.price)}</td>
                          <td>
                            <span>
                              {el.payments &&
                                el.payments.map(ell => (
                                  <React.Fragment key={ell.id}>
                                    {ell.name.toLowerCase() == 'd' ? (
                                      <span
                                        className="badge waves-effect white lighten-3 black-text tooltipped"
                                        data-tooltip="Depósito Bancário">
                                        {ell.name}
                                      </span>
                                    ) : ell.name.toLowerCase() ==
                                      'c' ? (
                                      <span
                                        className="badge waves-effect blue lighten-2 white-text tooltipped"
                                        data-tooltip="Cartão de crédito"
                                        data-position="top">
                                        {ell.name}
                                      </span>
                                    ) : ell.name.toLowerCase() ==
                                      'créditos' ? (
                                      <span className="badge waves-effect green lighten-2 white-text">
                                        {ell.name}
                                      </span>
                                    ) : ell.name.toLowerCase() ==
                                      'w' ? (
                                      <span
                                        className="badge waves-effect indigo darken-3 white-text tooltipped"
                                        data-tooltip="Western Union"
                                        data-position="top">
                                        {ell.name}
                                      </span>
                                    ) : (
                                      <span className="badge waves-effect orange lighten-3 white-text">
                                        {ell.name}
                                      </span>
                                    )}
                                  </React.Fragment>
                                ))}
                            </span>
                          </td>
                          <td>
                            {el.type.toLowerCase() == 'pe' ? (
                              <span
                                className="badge waves-effect blue lighten-2 white-text tooltipped"
                                data-tooltip="PRIORITY EXPRESS"
                                data-position="top">
                                {el.type}
                              </span>
                            ) : el.type.toLowerCase() == 'fc' ? (
                              <span
                                className="badge waves-effect blue lighten-2 white-text tooltipped"
                                data-tooltip="FIRST CLASS"
                                data-position="top">
                                {el.type}
                              </span>
                            ) : null}
                          </td>
                          <td>
                            {el.status.toLowerCase() == 'pendente' ? (
                              <span className="badge waves-effect orange lighten-3 white-text">
                                {el.status}
                              </span>
                            ) : el.status.toLowerCase() ==
                              'processando' ? (
                              <span className="badge waves-effect green lighten-2 white-text">
                                {el.status}
                              </span>
                            ) : el.status.toLowerCase() ==
                              'a pagar' ? (
                              <span className="badge waves-effect white black-text">
                                {el.status}
                              </span>
                            ) : el.status.toLowerCase() ==
                              'cancelado' ? (
                              <span className="badge waves-effect red">
                                {el.status}
                              </span>
                            ) : el.status.toLowerCase() ==
                              'recusado' ? (
                              <span className="badge waves-effect indigo darken-3">
                                {el.status}
                              </span>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="#" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Ticket de interação com o suporte"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  confirmation_number
                                </i>
                              </a>
                            </span>
                            {this.state.isProcessing ||
                            this.state.isToPay ||
                            this.state.isCancelled ||
                            this.state.isRefused ||
                            this.state.isAll ? (
                              <span
                                className="badge indigo darken-3 tooltipped modal-trigger"
                                data-target={'modal-pkgdet' + el.id}
                                data-tooltip="Ticket de interação com o suporte"
                                data-position="top">
                                <a href="" className="white-text">
                                  <i className="material-icons">
                                    remove_red_eye
                                  </i>
                                </a>
                              </span>
                            ) : null}
                            <span
                              className="badge white lighten-2 tooltipped"
                              data-tooltip="Adicionar nota"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  insert_drive_file
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {packages &&
                  packages.map(el => (
                    <Modal key={el.id} id={'modal-prod' + el.id}>
                      <table>
                        <thead>
                          <tr>
                            <th>Cód. Pacote</th>
                            <th>Data</th>
                            <th>Produto</th>
                            <th>Qtd</th>
                          </tr>
                        </thead>
                        <tbody>
                          {el.products &&
                            el.products.map(ell => (
                              <tr key={ell.id}>
                                <td>{ell.packageId}</td>
                                <td>
                                  {Helper.formatDate(
                                    'dd/MM/yyyy',
                                    ell.date,
                                  )}
                                </td>
                                <td>{ell.title}</td>
                                <td>{ell.qty}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </Modal>
                  ))}
                {packages &&
                  packages.map(el => (
                    <Modal key={el.id} id={'modal-pkgdet' + el.id}>
                      <table>
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>Cód. Pacote</th>
                            <th>Usuário BOX</th>
                            <th>Tipo frete</th>
                            <th>Status</th>
                            <th>Peso total</th>
                            <th>Peso user</th>
                            <th>Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          {el.packageDetails &&
                            el.packageDetails.map(ell => (
                              <tr key={ell.id}>
                                <td>
                                  {Helper.formatDate(
                                    'dd/MM/yyyy à\\s hh:MM:ss',
                                    ell.date,
                                  )}
                                </td>
                                <td>{ell.packageId}</td>
                                <td>{ell.userName}</td>
                                <td>
                                  <span className="badge green white-text lighten-2">
                                    {ell.freightType}
                                  </span>
                                </td>
                                <td>
                                  <span className="badge red white-text lighten-2">
                                    {ell.paymentStatus}
                                  </span>
                                </td>
                                <td>
                                  <span className="badge green white-text lighten-2">
                                    {ell.totalWeight}
                                  </span>
                                </td>
                                <td>
                                  <span className="badge green white-text lighten-2">
                                    {ell.userWeight}
                                  </span>
                                </td>
                                <td>
                                  <span className="badge green white-text lighten-2">
                                    {ell.totalPrice}
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </Modal>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PendingPackages;
