import React, {Component} from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Shortcuts from './Shortcuts';
import Breadcrumbs from './Breadcrumbs';
import {Link} from 'react-router-dom';

import queryString from 'query-string';

import Plugins from '../utils/Plugins';

import '../containers/documents.css';

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotValidated: false,
      isValidated: false,
      isAll: false,
    };

    this.props.history.listen((location, action) => {
      this.parseStateFromLocation(location.search);
    });
  }

  parseStateFromLocation = locationSearch => {
    const values = queryString.parse(locationSearch);

    this.setState({
      isNotValidated: typeof values['nao_validados'] !== 'undefined',
      isValidated: typeof values['validados'] !== 'undefined',
      isAll: typeof values['todos'] !== 'undefined',
    });
  };

  componentDidMount() {
    this.parseStateFromLocation(this.props.location.search);
  }

  componentDidUpdate() {
    Plugins.initDataTables();
  }

  render() {
    const images = require.context('../../public/docs', true);

    return (
      <React.Fragment>
        <Header />

        <Sidebar />

        <div id="main">
          <div className="row">
            <div className="col s12">
              <div className="container">
                <Shortcuts />
                <Breadcrumbs
                  crumbs={[
                    {label: 'Dashboard', url: '/dashboard'},
                    {label: 'Documentos', url: '/Documentos'},
                  ]}
                />

                <div id="docsFilters" className="filters">
                  <Link to="/documentos?nao_validados">
                    <button className="btn red white-text">
                      <i className="material-icons left">
                        thumb_down
                      </i>
                      Docs não validados
                      <span className="badge right grey lighten-1">
                        2
                      </span>
                    </button>
                  </Link>
                  <Link to="/documentos?validados">
                    <button className="btn white-text green lighten-2">
                      <i className="material-icons left">thumb_up</i>
                      Docs validados
                    </button>
                  </Link>
                  <Link to="/documentos?todos">
                    <button className="btn white-text indigo darken-3">
                      <i className="material-icons left">list</i>
                      Todos
                    </button>
                  </Link>
                </div>

                <div className="docs">
                  {this.state.isNotValidated ? (
                    <div>
                      <h5>Documento de Identidade</h5>
                      <div className="divider" />
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Documento</th>
                            <th>ID/Usuário</th>
                            <th>Validado?</th>
                            <th>Pontos creditados?</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <img
                                width="200"
                                src={images(
                                  './8de78eb9587cedc857d375d2a827d612.jpg',
                                )}
                              />
                            </td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect white black-text">
                                79702
                              </a>
                            </td>
                            <td>
                              <span
                                className="badge white-text red lighten-1 tooltipped"
                                data-tooltip="Validar documento"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge red tooltipped"
                                data-tooltip="Inserir pontos"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge white tooltipped"
                                data-tooltip="Excluir documento"
                                data-position="top">
                                <a href="" className="red-text">
                                  <i className="material-icons">
                                    close
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect white black-text">
                                79702
                              </a>
                            </td>
                            <td>
                              <span
                                className="badge white-text red tooltipped"
                                data-tooltip="Validar documento"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge red tooltipped"
                                data-tooltip="Inserir pontos"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge white tooltipped"
                                data-tooltip="Excluir documento"
                                data-position="top">
                                <a href="" className="red-text">
                                  <i className="material-icons">
                                    close
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}

                  {this.state.isNotValidated ? (
                    <div>
                      <h5>Comprovante de Residência</h5>
                      <div className="divider" />
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Documento</th>
                            <th>ID/Usuário</th>
                            <th>Validado?</th>
                            <th>Pontos creditados?</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <img
                                width="200"
                                src={images(
                                  './8de78eb9587cedc857d375d2a827d612.jpg',
                                )}
                              />
                            </td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect white black-text">
                                79702
                              </a>
                            </td>
                            <td>
                              <span
                                className="badge white-text red lighten-1 tooltipped"
                                data-tooltip="Validar documento"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge red tooltipped"
                                data-tooltip="Inserir pontos"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge white tooltipped"
                                data-tooltip="Excluir comprovante"
                                data-position="top">
                                <a href="" className="red-text">
                                  <i className="material-icons">
                                    close
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect white black-text">
                                79702
                              </a>
                            </td>
                            <td>
                              <span
                                className="badge white-text red tooltipped"
                                data-tooltip="Validar documento"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge red tooltipped"
                                data-tooltip="Inserir pontos"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge white tooltipped"
                                data-tooltip="Excluir comprovante"
                                data-position="top">
                                <a href="" className="red-text">
                                  <i className="material-icons">
                                    close
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}

                  {this.state.isValidated ? (
                    <div>
                      <h5>Documento de Identidade</h5>
                      <div className="divider" />
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Documento</th>
                            <th>ID/Usuário</th>
                            <th>Validado?</th>
                            <th>Pontos creditados?</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <img
                                width="200"
                                src="../../../docs/8de78eb9587cedc857d375d2a827d612.jpg"
                              />
                            </td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect white black-text">
                                79702
                              </a>
                            </td>
                            <td>
                              <span
                                className="badge white-text green lighten-2 tooltipped"
                                data-tooltip="Validar documento"
                                data-position="top">
                                Sim
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge red tooltipped"
                                data-tooltip="Inserir pontos"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge white tooltipped"
                                data-tooltip="Excluir documento"
                                data-position="top">
                                <a href="" className="red-text">
                                  <i className="material-icons">
                                    close
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect white black-text">
                                79702
                              </a>
                            </td>
                            <td>
                              <span
                                className="badge white-text green lighten-2 tooltipped"
                                data-tooltip="Validar documento"
                                data-position="top">
                                Sim
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge red tooltipped"
                                data-tooltip="Inserir pontos"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge white tooltipped"
                                data-tooltip="Excluir documento"
                                data-position="top">
                                <a href="" className="red-text">
                                  <i className="material-icons">
                                    close
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}

                  {this.state.isValidated ? (
                    <div>
                      <h5>Comprovante de Residência</h5>
                      <div className="divider" />
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Documento</th>
                            <th>ID/Usuário</th>
                            <th>Validado?</th>
                            <th>Pontos creditados?</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <img
                                width="200"
                                src={
                                  process.env.PUBLIC_URL
                                    ? process.env.PUBLIC_URL
                                    : '' +
                                      '/docs/8de78eb9587cedc857d375d2a827d612.jpg'
                                }
                              />
                            </td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect white black-text">
                                79702
                              </a>
                            </td>
                            <td>
                              <span
                                className="badge white-text green lighten-2 tooltipped"
                                data-tooltip="Validar documento"
                                data-position="top">
                                Sim
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge red tooltipped"
                                data-tooltip="Inserir pontos"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge white tooltipped"
                                data-tooltip="Excluir comprovante"
                                data-position="top">
                                <a href="" className="red-text">
                                  <i className="material-icons">
                                    close
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect white black-text">
                                79702
                              </a>
                            </td>
                            <td>
                              <span
                                className="badge white-text green lighten-2 tooltipped"
                                data-tooltip="Validar documento"
                                data-position="top">
                                Sim
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge red tooltipped"
                                data-tooltip="Inserir pontos"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge white tooltipped"
                                data-tooltip="Excluir comprovante"
                                data-position="top">
                                <a href="" className="red-text">
                                  <i className="material-icons">
                                    close
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}

                  {this.state.isAll ? (
                    <div>
                      <h5>Documento de Identidade</h5>
                      <div className="divider" />
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Documento</th>
                            <th>ID/Usuário</th>
                            <th>Validado?</th>
                            <th>Pontos creditados?</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <img
                                width="200"
                                src="../../../docs/8de78eb9587cedc857d375d2a827d612.jpg"
                              />
                            </td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect white black-text">
                                79702
                              </a>
                            </td>
                            <td>
                              <span
                                className="badge white-text green lighten-2 tooltipped"
                                data-tooltip="Validar documento"
                                data-position="top">
                                Sim
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge red tooltipped"
                                data-tooltip="Inserir pontos"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge white tooltipped"
                                data-tooltip="Excluir documento"
                                data-position="top">
                                <a href="" className="red-text">
                                  <i className="material-icons">
                                    close
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect white black-text">
                                79702
                              </a>
                            </td>
                            <td>
                              <span
                                className="badge white-text green lighten-2 tooltipped"
                                data-tooltip="Validar documento"
                                data-position="top">
                                Sim
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge red tooltipped"
                                data-tooltip="Inserir pontos"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge white tooltipped"
                                data-tooltip="Excluir documento"
                                data-position="top">
                                <a href="" className="red-text">
                                  <i className="material-icons">
                                    close
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}

                  {this.state.isAll ? (
                    <div>
                      <h5>Comprovante de Residência</h5>
                      <div className="divider" />
                      <table
                        id=""
                        className="striped highlight datatable">
                        <thead>
                          <tr>
                            <th>Documento</th>
                            <th>ID/Usuário</th>
                            <th>Validado?</th>
                            <th>Pontos creditados?</th>
                            <th>Ações</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <img
                                width="200"
                                src={
                                  process.env.PUBLIC_URL
                                    ? process.env.PUBLIC_URL
                                    : '' +
                                      '/docs/8de78eb9587cedc857d375d2a827d612.jpg'
                                }
                              />
                            </td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect white black-text">
                                79702
                              </a>
                            </td>
                            <td>
                              <span
                                className="badge white-text green lighten-2 tooltipped"
                                data-tooltip="Validar documento"
                                data-position="top">
                                Sim
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge red tooltipped"
                                data-tooltip="Inserir pontos"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge white tooltipped"
                                data-tooltip="Excluir comprovante"
                                data-position="top">
                                <a href="" className="red-text">
                                  <i className="material-icons">
                                    close
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>06/03/2018 às 15:52:44</td>
                            <td>
                              <a
                                href="#"
                                className="btn waves-effect white black-text">
                                79702
                              </a>
                            </td>
                            <td>
                              <span
                                className="badge white-text green lighten-2 tooltipped"
                                data-tooltip="Validar documento"
                                data-position="top">
                                Sim
                              </span>
                            </td>
                            <td>
                              <span
                                className="badge red tooltipped"
                                data-tooltip="Inserir pontos"
                                data-position="top">
                                Não
                              </span>
                            </td>
                            <td className="actions">
                              <span
                                className="badge white tooltipped"
                                data-tooltip="Excluir comprovante"
                                data-position="top">
                                <a href="" className="red-text">
                                  <i className="material-icons">
                                    close
                                  </i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Documents;
