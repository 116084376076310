import React, {Component} from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Shortcuts from './Shortcuts';
import Breadcrumbs from './Breadcrumbs';

import Plugins from '../utils/Plugins';

import './attachments.css';

class Attachments extends Component {
  componentDidMount() {
    Plugins.initDataTables();
  }

  componentDidUpdate() {
    //chamada para inicializar o DataTables
    Plugins.initDataTables();
  }

  submitAttachmentForm = e => {
    e.preventDefault();

    console.log('submitAttachmentForm');

    let elem = document.getElementById('modal_add_attachment');
    let instance = M.Modal.getInstance(elem);

    instance.close();
  };

  render() {
    const images = require.context('../../public/docs', true);
    return (
      <React.Fragment>
        <Header />

        <Sidebar />

        <div id="main">
          <div className="row">
            <div className="col s12">
              <div className="container">
                <Shortcuts />
                <Breadcrumbs
                  crumbs={[
                    {label: 'Dashboard', url: '/dashboard'},
                    {
                      label: 'Anexos avulsos',
                      url: '/anexos_avulsos',
                    },
                  ]}
                />

                <div id="modal_add_attachment" className="modal">
                  <form onSubmit={this.submitAttachmentForm}>
                    <div className="modal-content">
                      <h5>Inserir anexo</h5>
                      <div className="divider" />
                      <div className="file-field input-field">
                        <div className="btn">
                          <span>Anexo</span>
                          <input type="file" />
                        </div>
                        <div className="file-path-wrapper">
                          <input
                            className="file-path validate"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="divider" />
                    <div className="modal-footer">
                      <a
                        href="#!"
                        className="modal-close waves-effect black-text white btn">
                        Fechar
                      </a>
                      <button
                        type="submit"
                        className="waves-effect green btn">
                        Salvar
                      </button>
                    </div>
                  </form>
                </div>

                <div className="topFilters flex align-center">
                  <a
                    href="#"
                    className="btn white black-text flex align-center">
                    Anexos
                    <span className="badge right white-text blue darken-3">
                      1
                    </span>
                  </a>
                  <a
                    href="#modal_add_attachment"
                    className="btn green lighten-2 flex align-center modal-trigger">
                    <i className="material-icons left">add</i>
                    Anexos
                  </a>
                </div>
                <div className="divider" />

                <table id="" className="striped highlight datatable">
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Anexo</th>
                      <th>Ações</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>06/03/2018 às 15:52:44</td>
                      <td>
                        <img
                          width="150"
                          src={images(
                            './8de78eb9587cedc857d375d2a827d612.jpg',
                          )}
                          alt=""
                        />
                      </td>
                      <td className="actions">
                        <span
                          className="badge red tooltipped"
                          data-tooltip="Autorizar envio"
                          data-position="top">
                          <a href="" className="white-text">
                            <i className="material-icons">close</i>
                          </a>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Attachments;
