const Token = {
  get: () => {
    // eslint-disable-next-line
    const token = localStorage.getItem('TOKEN_PS');
    return token;
  },
  set: (value) => {
    // eslint-disable-next-line
    localStorage.setItem('TOKEN_PS', value);
  },
  remove: () => {
    // eslint-disable-next-line
    localStorage.removeItem('TOKEN_PS');
  },
};

export default Token;
