import React from 'react';

const PackagesDepositPaymentAPI = {
  async getCancelledPackagesCount() {
    return (await this.getCancelledPackages()).length;
  },

  async getProcessingPackagesCount() {
    return (await this.getProcessingPackages()).length;
  },

  async getRefusedPackagesCount() {
    return (await this.getRefusedPackages()).length;
  },

  async getApprovedPackagesCount() {
    return (await this.getApprovedPackages()).length;
  },

  async getAllPackagesCount() {
    return (await this.getAllPackages()).length;
  },

  async getCancelledPackages() {
    return [
      {
        id: '1',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        packageId: '60257',
        bank: 'Itaú',
        av: 'SA',
        value: 86.41,
        attachment: '60257-706e87475d2fcd496f189ec4f1ea39ec.jpg',
        status: 'Cancelado',
      },
      {
        id: '2',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        packageId: '60257',
        bank: 'Itaú',
        av: 'SA',
        value: 86.41,
        attachment: '60257-706e87475d2fcd496f189ec4f1ea39ec.jpg',
        status: 'Cancelado',
      },
      {
        id: '3',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        packageId: '60257',
        bank: 'Itaú',
        av: 'SA',
        value: 86.41,
        attachment: '60257-706e87475d2fcd496f189ec4f1ea39ec.jpg',
        status: 'Cancelado',
      },
      {
        id: '4',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        packageId: '60257',
        bank: 'Itaú',
        av: 'SA',
        value: 86.41,
        attachment: '60257-706e87475d2fcd496f189ec4f1ea39ec.jpg',
        status: 'Cancelado',
      },
    ];
  },

  async getProcessingPackages() {
    return [
      {
        id: '1',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        packageId: '60257',
        bank: 'Itaú',
        av: 'SA',
        value: 86.41,
        attachment: '60257-706e87475d2fcd496f189ec4f1ea39ec.jpg',
        status: 'Processando',
      },
      {
        id: '2',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        packageId: '60257',
        bank: 'Itaú',
        av: 'SA',
        value: 86.41,
        attachment: '60257-706e87475d2fcd496f189ec4f1ea39ec.jpg',
        status: 'Processando',
      },
    ];
  },

  async getRefusedPackages() {
    return [
      {
        id: '1',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        packageId: '60257',
        bank: 'Itaú',
        av: 'SA',
        value: 86.41,
        attachment: '60257-706e87475d2fcd496f189ec4f1ea39ec.jpg',
        status: 'Recusado',
      },
      {
        id: '2',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        packageId: '60257',
        bank: 'Itaú',
        av: 'SA',
        value: 86.41,
        attachment: '60257-706e87475d2fcd496f189ec4f1ea39ec.jpg',
        status: 'Recusado',
      },
      {
        id: '3',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        packageId: '60257',
        bank: 'Itaú',
        av: 'SA',
        value: 86.41,
        attachment: '60257-706e87475d2fcd496f189ec4f1ea39ec.jpg',
        status: 'Recusado',
      },
      {
        id: '4',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        packageId: '60257',
        bank: 'Itaú',
        av: 'SA',
        value: 86.41,
        attachment: '60257-706e87475d2fcd496f189ec4f1ea39ec.jpg',
        status: 'Recusado',
      },
      {
        id: '5',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        packageId: '60257',
        bank: 'Itaú',
        av: 'SA',
        value: 86.41,
        attachment: '60257-706e87475d2fcd496f189ec4f1ea39ec.jpg',
        status: 'Recusado',
      },
    ];
  },

  async getApprovedPackages() {
    return [
      {
        id: '1',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        packageId: '60257',
        bank: 'Itaú',
        av: 'SA',
        value: 86.41,
        attachment: '60257-706e87475d2fcd496f189ec4f1ea39ec.jpg',
        status: 'Aprovado',
      },
    ];
  },

  async getAllPackages() {
    let allPackages = [].concat(
      await this.getProcessingPackages(),
      await this.getApprovedPackages(),
      await this.getCancelledPackages(),
      await this.getRefusedPackages(),
    );

    console.log(allPackages);

    return allPackages;
  },
};

export default PackagesDepositPaymentAPI;
