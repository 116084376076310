import React, {Component} from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Shortcuts from './Shortcuts';
import Breadcrumbs from './Breadcrumbs';

import queryString from 'query-string';

import Plugins from '../utils/Plugins';

import {Link} from 'react-router-dom';

class DetachedPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPending: false,
      isProcessing: false,
      isCancelled: false,
      isRefused: false,
      isApproved: false,
      isAll: false,
      isAdd: false,
    };

    this.props.history.listen((location, action) => {
      this.parseStateFromLocation(location.search);
    });
  }

  parseStateFromLocation = locationSearch => {
    const values = queryString.parse(locationSearch);

    this.setState({
      isPending: typeof values['pendente'] !== 'undefined',
      isProcessing: typeof values['processando'] !== 'undefined',
      isCancelled: typeof values['cancelados'] !== 'undefined',
      isRefused: typeof values['recusados'] !== 'undefined',
      isApproved: typeof values['aprovados'] !== 'undefined',
      isAll: typeof values['todos'] !== 'undefined',
      isAdd: typeof values['cadastro'] !== 'undefined',
    });
  };

  componentDidMount() {
    this.parseStateFromLocation(this.props.location.search);
    Plugins.initDataTables();
  }

  componentDidUpdate() {
    Plugins.initDataTables();
    M.AutoInit();
    //console.log(M);
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <Sidebar />

        <div id="main">
          <div className="row">
            <div className="col s12">
              <div className="container">
                <Shortcuts />
                <Breadcrumbs
                  crumbs={[
                    {label: 'Dashboard', url: '/dashboard'},
                    {label: 'Pacotes', url: '/pacotes'},
                    {
                      label: 'Pagamentos avulsos com cartões',
                      url: '',
                    },
                  ]}
                />
                <div id="packetFilters" className="filters">
                  <Link to="?pendente">
                    <button className="btn white black-text">
                      <i className="material-icons left">
                        rotate_right
                      </i>
                      Pendente
                      <span className="badge grey lighten-1">34</span>
                    </button>
                  </Link>
                  <Link to="?processando">
                    <button className="btn orange white-text">
                      <i className="material-icons left">
                        rotate_right
                      </i>
                      Processando
                    </button>
                  </Link>
                  <Link to="?cancelados">
                    <button className="btn red white-text">
                      <i className="material-icons left">
                        thumb_down
                      </i>
                      Cancelados
                    </button>
                  </Link>
                  <Link to="?recusados">
                    <button className="btn white-text indigo darken-3">
                      <i className="material-icons left">
                        thumb_down
                      </i>
                      Recusados
                    </button>
                  </Link>
                  <Link to="?aprovados">
                    <button className="btn white-text green lighten-2">
                      <i className="material-icons left">thumb_up</i>
                      Aprovados
                    </button>
                  </Link>
                  <Link to="?todos">
                    <button className="btn white-text blue lighten-2">
                      <i className="material-icons left">list</i>
                      Todos
                    </button>
                  </Link>
                  <Link to="?cadastro">
                    <button className="btn black-text white lighten-2">
                      <i className="material-icons left">add</i>
                      Inserir
                    </button>
                  </Link>
                </div>

                <div className="divider" />

                {this.state.isPending ? (
                  <div>
                    <table
                      id=""
                      className="striped highlight datatable">
                      <thead>
                        <tr>
                          <th>Código</th>
                          <th>Data</th>
                          <th>User</th>
                          <th>AV</th>
                          <th>Valor</th>
                          <th>Descrição</th>
                          <th>Del</th>
                          <th>Status</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text grey lighten-1 tooltipped"
                              data-tooltip="Sem avaliação - Cliente"
                              data-position="top">
                              SA
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge light-blue accent-3">
                              À pagar
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text green lighten-2 tooltipped"
                              data-tooltip="Cliente bom"
                              data-position="top">
                              CB
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge orange">
                              Pendente
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text orange tooltipped"
                              data-tooltip="Suspeito - Cliente"
                              data-position="top">
                              SU
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge light-blue accent-3">
                              À pagar
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : null}

                {this.state.isProcessing ? (
                  <div>
                    <table
                      id=""
                      className="striped highlight datatable">
                      <thead>
                        <tr>
                          <th>Código</th>
                          <th>Data</th>
                          <th>User</th>
                          <th>AV</th>
                          <th>Valor</th>
                          <th>Descrição</th>
                          <th>Del</th>
                          <th>Status</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text grey lighten-1 tooltipped"
                              data-tooltip="Sem avaliação - Cliente"
                              data-position="top">
                              SA
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge light-blue accent-3">
                              Processando
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text green lighten-2 tooltipped"
                              data-tooltip="Cliente bom"
                              data-position="top">
                              CB
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge light-blue accent-3">
                              Processando
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text orange tooltipped"
                              data-tooltip="Suspeito - Cliente"
                              data-position="top">
                              SU
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge light-blue accent-3">
                              Processando
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : null}

                {this.state.isCancelled ? (
                  <div>
                    <h5 id="" className="red-text text-accent-2">
                      <i className="material-icons left">warning</i>
                      Nenhum registro encontrado no sistema
                    </h5>
                  </div>
                ) : null}

                {this.state.isRefused ? (
                  <div>
                    <table
                      id=""
                      className="striped highlight datatable">
                      <thead>
                        <tr>
                          <th>Código</th>
                          <th>Data</th>
                          <th>User</th>
                          <th>AV</th>
                          <th>Valor</th>
                          <th>Descrição</th>
                          <th>Del</th>
                          <th>Status</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text grey lighten-1 tooltipped"
                              data-tooltip="Sem avaliação - Cliente"
                              data-position="top">
                              SA
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge red">
                              Recusado
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text green lighten-2 tooltipped"
                              data-tooltip="Cliente bom"
                              data-position="top">
                              CB
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge red">
                              Recusado
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text orange tooltipped"
                              data-tooltip="Suspeito - Cliente"
                              data-position="top">
                              SU
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge red">
                              Recusado
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : null}

                {this.state.isApproved ? (
                  <div>
                    <table
                      id=""
                      className="striped highlight datatable">
                      <thead>
                        <tr>
                          <th>Código</th>
                          <th>Data</th>
                          <th>User</th>
                          <th>AV</th>
                          <th>Valor</th>
                          <th>Descrição</th>
                          <th>Del</th>
                          <th>Status</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text grey lighten-1 tooltipped"
                              data-tooltip="Sem avaliação - Cliente"
                              data-position="top">
                              SA
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge green lighten-2">
                              Aprovado
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text green lighten-2 tooltipped"
                              data-tooltip="Cliente bom"
                              data-position="top">
                              CB
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge green lighten-2">
                              Aprovado
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text orange tooltipped"
                              data-tooltip="Suspeito - Cliente"
                              data-position="top">
                              SU
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge green lighten-2">
                              Aprovado
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : null}

                {this.state.isAll ? (
                  <div>
                    <table
                      id=""
                      className="striped highlight datatable">
                      <thead>
                        <tr>
                          <th>Código</th>
                          <th>Data</th>
                          <th>User</th>
                          <th>AV</th>
                          <th>Valor</th>
                          <th>Descrição</th>
                          <th>Del</th>
                          <th>Status</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text grey lighten-1 tooltipped"
                              data-tooltip="Sem avaliação - Cliente"
                              data-position="top">
                              SA
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge orange">
                              Pendente
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text green lighten-2 tooltipped"
                              data-tooltip="Cliente bom"
                              data-position="top">
                              CB
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge green lighten-2">
                              Aprovado
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text orange tooltipped"
                              data-tooltip="Suspeito - Cliente"
                              data-position="top">
                              SU
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge light-blue accent-3">
                              À Pagar
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>06/03/2018 às 15:52:44</td>
                          <td>
                            <a
                              href="#"
                              className="btn waves-effect white black-text">
                              79702
                            </a>
                          </td>
                          <td>
                            <span
                              className="badge white-text orange tooltipped"
                              data-tooltip="Suspeito - Cliente"
                              data-position="top">
                              SU
                            </span>
                          </td>
                          <td>
                            <span className="">86.41</span>
                          </td>
                          <td>Transf de pacote para conta 34150</td>
                          <td>
                            <span
                              className="badge white-text red tooltipped"
                              data-tooltip="Deletar pagamento"
                              data-position="top">
                              Não
                            </span>
                          </td>
                          <td>
                            <span className="badge red">
                              Recusado
                            </span>
                          </td>
                          <td className="actions">
                            <span
                              className="badge orange tooltipped"
                              data-tooltip="Editar pacote"
                              data-position="top">
                              <a href="" className="white-text">
                                <i className="material-icons">edit</i>
                              </a>
                            </span>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Editar Status"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  search
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : null}

                {this.state.isAdd ? (
                  <div>
                    <h5>Inserir pagamento</h5>
                    <div className="row">
                      <div className="divider" />
                      <form className="col s12">
                        <div className="row">
                          <div className="input-field col s6">
                            <input
                              id="userId"
                              type="text"
                              className="validate"
                              name="userId"
                            />
                            <label htmlFor="userId">ID/Usuário</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s6">
                            <input
                              id="value"
                              type="text"
                              className="validate"
                              name="value"
                            />
                            <label htmlFor="value">Valor</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s6">
                            <textarea
                              name="description"
                              id="description"
                              id=""
                              rows="10"
                              className="materialize-textarea"
                            />
                            <label htmlFor="description">
                              Descrição
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s6">
                            <select name="creditcard">
                              <option value="YES">SIM</option>
                              <option value="NO">NÃO</option>
                            </select>
                            <label>Cartão</label>
                          </div>
                        </div>
                        <div className="divider" />
                        <a className="btn white black-text">
                          <i className="material-icons left">
                            refresh
                          </i>
                          Voltar
                        </a>
                        <button
                          type="submit"
                          className="btn green lighten-2">
                          <i className="material-icons left">add</i>
                          Inserir
                        </button>
                      </form>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DetachedPayments;
