const DeletedPackagesAPI = {
  async getDeletedPackages() {
    return [
      {
        id: '2',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'C'}],
        type: 'PE',
        status: 'Deletado',
      },
      {
        id: '3',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'C'}, {name: 'Créditos'}],
        type: 'PE',
        status: 'Deletado',
      },
      {
        id: '4',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'C'}, {name: 'Créditos'}, {name: 'W'}],
        type: 'PE',
        status: 'Deletado',
      },
      {
        id: '5',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'Pendente'}],
        type: 'PE',
        status: 'Deletado',
      },
    ];
  },
};

export default DeletedPackagesAPI;
