import React, {Component} from 'react';
import M from 'materialize-css';

//import './header.css';

class Header extends Component {
  onPriceSimulatorClick = () => {
    //cors.io apenas para teste, remover em produção
    /*const ret = await axios.get(
      "https://cors.io/?https://box4world.com/calculator"
    );*/
    //const modal = document.querySelector("#modal1 .modal-content");
    let elem = document.querySelector('#modal1');
    let instance = M.Modal.getInstance(elem);

    instance.open();
  };

  render() {
    const images = require.context(
      '../../public/app-assets/images',
      true,
    );

    return (
      <header className="page-topbar" id="header">
        <div id="modal1" className="modal">
          <div className="modal-content">
            <iframe
              width="100%"
              height="450"
              frameBorder="0"
              src="https://box4world.com/calculator"
            />
          </div>
          <div className="modal-footer">
            <a
              href="#!"
              className="modal-close waves-effect waves-green btn-flat">
              Fechar
            </a>
          </div>
        </div>

        <div className="navbar navbar-fixed">
          <nav className="navbar-main navbar-color nav-collapsible sideNav-lock navbar-light">
            <div className="nav-wrapper">
              <ul className="navbar-list right">
                <li>
                  <ul
                    id="nav-mobile"
                    className="right hide-on-med-and-down">
                    <li className="orange" id="priceSimulator">
                      <a
                        href="#"
                        onClick={this.onPriceSimulatorClick}
                        className="white-text">
                        <i className="material-icons">dvr</i>
                      </a>
                    </li>
                    <li className="orange">
                      <a href="/anti_fraud" className="white-text">
                        <i className="material-icons left">
                          remove_red_eye
                        </i>
                        PAA
                      </a>
                    </li>
                    <li className="blue lighten-2">
                      <a href="" className="white-text">
                        <i className="material-icons left">
                          local_offer
                        </i>
                        TCO-S
                        <span className="badge green">0</span>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <i className="material-icons left">
                          local_offer
                        </i>
                        TCO
                        <span className="badge white-text red">
                          17
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    className="waves-effect waves-block waves-light profile-button"
                    href="javascript:void(0);"
                    data-target="profile-dropdown">
                    <span className="avatar-status avatar-online">
                      <img
                        src={images('./avatar/avatar-7.png')}
                        alt="avatar"
                      />
                      <i />
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    className="waves-effect waves-block waves-light"
                    id="btnSair"
                    href="#">
                    <i className="material-icons">exit_to_app</i>
                  </a>
                </li>
              </ul>

              <ul className="dropdown-content" id="profile-dropdown">
                <li>
                  <a
                    className="grey-text text-darken-1"
                    href="user-profile-page.html">
                    <i className="material-icons">person_outline</i>{' '}
                    Meu Perfil
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a
                    className="grey-text text-darken-1"
                    href="user-login.html">
                    <i className="material-icons">keyboard_tab</i>{' '}
                    Sair
                  </a>
                </li>
              </ul>
            </div>
            <nav className="display-none search-sm">
              <div className="nav-wrapper">
                <form>
                  <div className="input-field">
                    <input
                      className="search-box-sm"
                      type="search"
                      required=""
                    />
                    <label className="label-icon" htmlFor="search">
                      <i className="material-icons search-sm-icon">
                        search
                      </i>
                    </label>
                    <i className="material-icons search-sm-close">
                      close
                    </i>
                  </div>
                </form>
              </div>
            </nav>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
