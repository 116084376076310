import React, {Component} from 'react';

import $ from 'jquery';
import dt from 'datatables.net-dt';
/*= ===============================================================================
  Item Name: Materialize - Material Design Admin Template
  Version: 4.0
  Author: PIXINVENT
  Author URL: https://themeforest.net/user/pixinvent/portfolio
================================================================================ */

/* Preloader */
class Plugins extends React.Component {
  static initDataTables() {
    $('.datatable').each(function() {
      if (!$.fn.dataTable.isDataTable(this)) {
        $(this).on('draw.dt', () => {
          let currTable = $(this).DataTable();
          let currTable2 = $(this);
          let context = $(this).parent();
          let btnPrev = $('.paginate_button.previous', context);
          let btnNext = $('.paginate_button.next', context);
          $(
            '.dataTables_paginate > .paginate_button.previous',
            context,
          ).replaceWith(
            $('<a class="prev" id="searchResultTable_prev" />'),
          );
          $(
            '.dataTables_paginate > .paginate_button.next',
            context,
          ).replaceWith(
            $('<a class="next" id="searchResultTable_next" />'),
          );

          $('#searchResultTable_prev').on('click', function() {
            console.log(currTable);
            $(currTable).fnPageChange('previous');
          });

          $('#searchResultTable_next').on('click', function() {
            console.log(currTable, this);
            $(currTable).fnPageChange('next');
          });

          $('.dataTables_paginate .prev', context).append(btnPrev);
          $('.dataTables_paginate .next', context).append(btnNext);
          $(
            '.dataTables_paginate.paging_simple_numbers span',
            context,
          ).addClass('badge');
        });

        $(this).DataTable({
          dom:
            '<"row"<"col s2"l><"col s2 push-s8"f>>t<"divider"><"row"<"col s2"i><"col s2 push-s8 right-align"p>>r',
          language: {
            lengthMenu: 'Mostrar _MENU_ registros por página',
            zeroRecords: 'Nada encontrado - desculpe',
            info: 'Mostrando página _PAGE_ de _PAGES_',
            infoEmpty: 'Nenhum registro disponível',
            infoFiltered: '(filtrado de _MAX_ registros totais)',
            paginate: {
              previous: 'Anterior',
              next: 'Próximo',
            },
            search: 'Buscar',
            autoWidth: false,
          },
        });
        //this.columns.adjust().draw();
      }
    });
  }

  static destroyTables() {
    $('.datatable').each(function() {
      if ($.fn.dataTable.isDataTable(this)) {
        $(this).off('init.dt');
        $(this)
          .DataTable()
          .destroy();
      }
    });
  }

  constructor(props) {
    super(props);
    $(() => {
      //document.addEventListener('DOMContentLoaded', function() {
      //});
      //materialize calendar i18n
      const options = {
        format: 'dd/mm/yyyy',
        i18n: {
          cancel: 'Cancelar',
          clear: 'Limpar',
          done: 'Ok',
          previousMonth: '‹',
          nextMonth: '›',
          months: [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
          ],
          monthsShort: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ],
          weekdays: [
            'Domingo',
            'Segunda-feira',
            'Terça-feira',
            'Quarta-feira',
            'Quinta-feira',
            'Sexta-feira',
            'Sábado',
          ],
          weekdaysShort: [
            'Dom',
            'Seg',
            'Ter',
            'Qua',
            'Qui',
            'Sex',
            'Sab',
          ],
          weekdaysAbbrev: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        },
        onClose: function() {
          const elems = document.querySelectorAll(
            '#card-stats .collection',
          );

          for (let i = 0; i < elems.length; i++) {
            elems.item(i).style.display = 'block';
          }
        },
      };

      //M.AutoInit();

      const elems = document.querySelector('.datepicker');
      M.Datepicker.init(elems, options);
    });
  }
}

export default Plugins;
