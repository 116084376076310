const PackagesAPI = {
  async getPendingPackagesCount() {
    let cnt = (await this.getPendingPackages()).length;
    return cnt;
  },

  async getCancelledPackagesCount() {
    let cnt = (await this.getCancelledPackages()).length;
    return cnt;
  },

  async getProcessingPackagesCount() {
    let cnt = (await this.getProcessingPackages()).length;
    return cnt;
  },

  async getToPayPackagesCount() {
    let cnt = (await this.getToPayPackages()).length;
    return cnt;
  },

  async getRefusedPackagesCount() {
    let cnt = (await this.getRefusedPackages()).length;
    return cnt;
  },

  async getAllPackagesCount() {
    let cnt = (await this.getAllPackages()).length;
    return cnt;
  },

  async getPendingPackages() {
    return [
      {
        id: '1',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'Pendente'}],
        type: 'PE',
        status: 'Pendente',
      },
    ];
  },

  async getCancelledPackages() {
    return [
      {
        id: '2',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'C'}],
        type: 'PE',
        status: 'Cancelado',
      },
      {
        id: '3',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'C'}, {name: 'Créditos'}],
        type: 'PE',
        status: 'Cancelado',
      },
      {
        id: '4',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'C'}, {name: 'Créditos'}, {name: 'W'}],
        type: 'PE',
        status: 'Cancelado',
      },
      {
        id: '5',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'Pendente'}],
        type: 'PE',
        status: 'Cancelado',
      },
    ];
  },

  async getProcessingPackages() {
    return [
      {
        id: '6',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'D'}],
        type: 'FC',
        status: 'Processando',
      },
      {
        id: '7',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'D'}],
        type: 'FC',
        status: 'Processando',
      },
    ];
  },

  async getToPayPackages() {
    return [
      {
        id: '8',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'Pendente'}],
        type: 'PE',
        status: 'A Pagar',
      },
      {
        id: '9',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'Pendente'}],
        type: 'PE',
        status: 'A Pagar',
      },
      {
        id: '10',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'Pendente'}],
        type: 'PE',
        status: 'A Pagar',
      },
    ];
  },

  async getRefusedPackages() {
    return [
      {
        id: '11',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        weight: '1',
        price: '1',
        paymentStatus: 'Pendente',
        type: 'PE',
        status: 'Recusado',
      },
      {
        id: '12',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'Pendente'}],
        type: 'PE',
        status: 'Recusado',
      },
      {
        id: '13',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'Pendente'}],
        type: 'PE',
        status: 'Recusado',
      },
      {
        id: '14',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'Pendente'}],
        type: 'PE',
        status: 'Recusado',
      },
      {
        id: '15',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        weight: '1',
        price: '1',
        payments: [{name: 'Pendente'}],
        type: 'PE',
        status: 'Recusado',
      },
    ];
  },

  async getAllPackages() {
    let allPackages = [].concat(
      await this.getPendingPackages(),
      await this.getProcessingPackages(),
      await this.getToPayPackages(),
      await this.getCancelledPackages(),
      await this.getRefusedPackages(),
    );

    return allPackages;
  },
};

export default PackagesAPI;
