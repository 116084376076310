import React, {Component} from 'react';
import './style.scss';
import '../../assets/css/pages/login.min.css';
import {Consumer} from '../../context';

import M from 'materialize-css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      pass: '',
      token: false,
    };
  }

  onSubmit = async (dispatch, e) => {
    e.preventDefault();

    const {user, pass} = this.state;

    if (user === '') {
      return;
    }
    if (pass === '') {
      return;
    }

    if ('123456' != pass || 'thomas' != user) {
      var instance = M.Modal.init(document.getElementById('modal1'));
      instance.open();

      return;
    }

    this.props.history.push('/dashboard');
  };

  onChange = e => this.setState({[e.target.name]: e.target.value});

  render() {
    const {user, pass, errors} = this.state;

    return (
      <Consumer>
        {value => {
          const {token} = this.state;
          const {dispatch} = value;

          return !token ? (
            <div
              className="vertical-layout page-header-light vertical-menu-collapsible vertical-dark-menu 1-column login-bg  blank-page blank-page"
              data-open="click"
              data-menu="vertical-dark-menu"
              data-col="1-column">
              <div id="modal1" className="modal">
                <div className="modal-content">
                  <h4>Aviso</h4>
                  <p>Usuário ou senha incorretos!</p>
                </div>
                <div className="modal-footer">
                  <a
                    href="#!"
                    className="modal-action modal-close waves-effect waves-green btn-flat">
                    Fechar
                  </a>
                </div>
              </div>

              <div className="row">
                <div className="col s12">
                  <div className="container">
                    <div id="login-page" className="row">
                      <div className="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                        <form
                          className="login-form"
                          onSubmit={this.onSubmit.bind(
                            this,
                            dispatch,
                          )}>
                          <div className="row">
                            <div className="input-field col s12">
                              <h5 className="ml-4">
                                Área Administrativa
                              </h5>
                            </div>
                          </div>
                          <div className="row margin">
                            <div className="input-field col s12">
                              <i className="material-icons prefix pt-2">
                                person_outline
                              </i>
                              <input
                                id="user"
                                name="user"
                                onChange={this.onChange}
                                type="text"
                                required="required"
                                className="validate"
                              />
                              <span
                                className="helper-text"
                                data-error="Este campo não pode estar vazio"
                              />

                              <label
                                htmlFor="user"
                                className="center-align">
                                Usuário
                              </label>
                            </div>
                          </div>
                          <div className="row margin">
                            <div className="input-field col s12">
                              <i className="material-icons prefix pt-2">
                                lock_outline
                              </i>
                              <input
                                id="pass"
                                name="pass"
                                onChange={this.onChange}
                                type="password"
                                required="required"
                                className="validate"
                              />
                              <span
                                className="helper-text"
                                data-error="Este campo não pode estar vazio"
                              />
                              <label htmlFor="pass">Senha</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col s12 m12 l12 ml-2 mt-1">
                              <p>
                                <label>
                                  <input type="checkbox" />
                                  <span>Lembrar-me</span>
                                </label>
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12">
                              <button
                                type="submit"
                                className="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12">
                                Logar
                              </button>
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s6 m6 l6" />
                            <div className="input-field col s6 m6 l6">
                              <p className="margin right-align medium-small">
                                <a href="user-forgot-password.html">
                                  Esqueceu a senha?
                                </a>
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null;
        }}
      </Consumer>
    );
  }
}

export default Login;
