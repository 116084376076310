const RenewedPackagesAPI = {
  async getRenewedPackages() {
    return [
      {
        id: '2',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        renewedPrice: 15.0,
        credits: 0.0,
        days: 100,
        payments: [{name: 'C'}],
        type: 'PE',
        status: 'Renovado',
        confirmed: false,
        locked: false,
      },
      {
        id: '3',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        renewedPrice: 15.0,
        credits: 0.0,
        days: 201,
        payments: [{name: 'C'}, {name: 'Créditos'}],
        type: 'PE',
        status: 'Renovado',
        confirmed: false,
        locked: true,
      },
      {
        id: '4',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        renewedPrice: 15.0,
        credits: 0.0,
        days: 205,
        payments: [{name: 'C'}, {name: 'Créditos'}, {name: 'W'}],
        type: 'PE',
        status: 'Renovado',
        confirmed: true,
        locked: false,
      },
      {
        id: '5',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        products: [
          {
            packageId: '310520',
            date: '2019-05-14T16:32:08.746Z',
            title: 'BBRINQUEDOS VARIADOS',
            qty: 1,
          },
        ],
        packageDetails: [
          {
            date: '2019-05-14T16:32:08.746Z',
            packageId: '60257',
            userName: 'CRISTIANE',
            freightType: 'FIRST CLASS',
            paymentStatus: 'A PAGAR',
            totalWeight: 3.0,
            userWeight: '3+3',
            totalPrice: 80.22,
          },
        ],
        renewedPrice: 15.0,
        credits: 0.0,
        days: 210,
        payments: [{name: 'Pendente'}],
        type: 'PE',
        status: 'Renovado',
        confirmed: true,
        locked: true,
      },
    ];
  },
};

export default RenewedPackagesAPI;
