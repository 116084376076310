import React, {Component} from 'react';

import Header from './Header';
import Sidebar from './Sidebar';
import Breadcrumbs from './Breadcrumbs';
import Shortcuts from './Shortcuts';

import RenewedPackagesAPI from '../services/RenewedPackages';

import '../assets/js/material-dialog.min';

import Plugins from '../utils/Plugins';

import Helper from '../utils/Helper';

import {Modal} from 'react-materialize';

import {Link} from 'react-router-dom';

import '../assets/css/dataTables.material.min.css';

class RenewedPackages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      packages: null,
      packagesCnt: 0,
    };
  }
  async updatePackages() {
    Plugins.destroyTables();
    let packages = null;
    let packagesCnt = 0;

    packages = await RenewedPackagesAPI.getRenewedPackages();
    packagesCnt = packages.length;

    this.setState({packages, packagesCnt}, () => {
      Plugins.initDataTables();
      M.AutoInit();
    });
  }

  async componentDidMount() {
    this.updatePackages();
  }

  onConfirmPackage = packageId => {
    MaterialDialog.dialog('Deseja mesmo renovar este pacote?', {
      title: 'Renovar pacote',
      buttons: {
        confirm: {
          text: 'OK',
          callback: () => {
            console.log('packageID ', packageId);
          },
        },
        close: {
          text: 'Cancelar',
        },
      },
    });
  };

  onUnlockPackage = packageId => {
    MaterialDialog.dialog('Deseja mesmo destravar este pacote?', {
      title: 'Destravar pacote',
      buttons: {
        confirm: {
          text: 'OK',
          callback: () => {
            console.log('packageID ', packageId);
          },
        },
        close: {
          text: 'Cancelar',
        },
      },
    });
  };

  onLockPackage = packageId => {
    MaterialDialog.dialog('Deseja mesmo travar este pacote?', {
      title: 'Travar pacote',
      buttons: {
        confirm: {
          text: 'OK',
          callback: () => {
            console.log('packageID ', packageId);
          },
        },
        close: {
          text: 'Cancelar',
        },
      },
    });
  };

  render() {
    const {packages, packagesCnt} = this.state;

    return (
      <React.Fragment>
        <Header />

        <Sidebar />

        <div id="main">
          <div className="row">
            <div className="col s12">
              <div className="container">
                <Shortcuts />
                <Breadcrumbs
                  crumbs={[
                    {label: 'Dashboard', url: '/dashboard'},
                    {
                      label: 'Pacotes renovados',
                      url: '/pacotes_renovados',
                    },
                  ]}
                />
                <div className="topFilters flex align-center">
                  <Link
                    to="/pacotes_deletados"
                    className="btn orange white-text flex align-center">
                    <i className="material-icons left">
                      rotate_right
                    </i>
                    Pacotes
                    {packagesCnt ? (
                      <span className="badge right white-text grey darken-1">
                        {packagesCnt}
                      </span>
                    ) : null}
                  </Link>
                </div>
                <div className="divider" />
              </div>
            </div>
            <div className="col s2 offset-s10">
              <div className="container">
                <div className="row">
                  <form className="col s12" />
                </div>
              </div>
            </div>
            <div className="col s12">
              <div className="container">
                <table
                  id="searchResultsTable"
                  className="striped highlight datatable">
                  <thead>
                    <tr>
                      <th>Pacote</th>
                      <th>ID / Usuário</th>
                      <th>Valor Ren.</th>
                      <th>Créditos</th>
                      <th>Dias</th>
                      <th>Pagamento</th>
                      <th>Confirmado?</th>
                      <th>Travado?</th>
                      <th>Ações</th>
                    </tr>
                  </thead>

                  {packages && (
                    <tbody>
                      {packages.map(el => (
                        <tr key={el.id}>
                          <td>{el.id}</td>
                          <td>
                            <span
                              className="btn waves-effect white black-text tooltipped"
                              data-tooltip="Visualizar Usuário"
                              data-position="top">
                              {el.user.id} / {el.user.name}
                            </span>
                          </td>
                          <td>
                            {Helper.formatCurrency(el.renewedPrice)}
                          </td>
                          <td>{Helper.formatCurrency(el.credits)}</td>
                          <td>{el.days}</td>
                          <td>
                            <span>
                              {el.payments &&
                                el.payments.map(ell => (
                                  <React.Fragment key={ell.id}>
                                    {ell.name == 'd' ? (
                                      <span
                                        className="badge waves-effect white lighten-3 black-text tooltipped"
                                        data-tooltip="Depósito Bancário">
                                        {ell.name}
                                      </span>
                                    ) : ell.name.toLowerCase() ==
                                      'c' ? (
                                      <span
                                        className="badge waves-effect blue lighten-2 white-text tooltipped"
                                        data-tooltip="Cartão de crédito"
                                        data-position="top">
                                        {ell.name}
                                      </span>
                                    ) : ell.name.toLowerCase() ==
                                      'créditos' ? (
                                      <span className="badge waves-effect green lighten-2 white-text">
                                        {ell.name}
                                      </span>
                                    ) : ell.name.toLowerCase() ==
                                      'w' ? (
                                      <span
                                        className="badge waves-effect indigo darken-3 white-text tooltipped"
                                        data-tooltip="Western Union"
                                        data-position="top">
                                        {ell.name}
                                      </span>
                                    ) : (
                                      <span className="badge waves-effect orange lighten-3 white-text">
                                        {ell.name}
                                      </span>
                                    )}
                                  </React.Fragment>
                                ))}
                            </span>
                          </td>
                          <td>
                            {el.confirmed ? (
                              <span
                                className="badge waves-effect green lighten-2 white-text tooltipped"
                                data-tooltip="Renovação confirmada"
                                data-position="top">
                                SIM
                              </span>
                            ) : (
                              <span
                                onClick={() => {
                                  this.onConfirmPackage(el.id);
                                }}
                                className="badge waves-effect red white-text tooltipped"
                                data-tooltip="Confirmar pacote"
                                data-position="top">
                                NÃO
                              </span>
                            )}
                          </td>
                          <td>
                            {el.locked ? (
                              <span
                                onClick={() => {
                                  this.onUnlockPackage(el.id);
                                }}
                                className="badge waves-effect green lighten-2 white-text tooltipped"
                                data-tooltip="Destravar pacote"
                                data-position="top">
                                SIM
                              </span>
                            ) : (
                              <span
                                onClick={() => {
                                  this.onLockPackage(el.id);
                                }}
                                className="badge waves-effect red white-text tooltipped"
                                data-tooltip="Travar pacote"
                                data-position="top">
                                NÃO
                              </span>
                            )}
                          </td>
                          <td>
                            <span
                              className="badge white tooltipped"
                              data-tooltip="Ticket de interação com o suporte"
                              data-position="top">
                              <a href="" className="black-text">
                                <i className="material-icons">
                                  confirmation_number
                                </i>
                              </a>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RenewedPackages;
