import React, {Component} from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Shortcuts from './Shortcuts';
import Breadcrumbs from './Breadcrumbs';

class Dolar extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />

        <Sidebar />

        <div id="main">
          <div className="row">
            <div className="col s12">
              <div className="container">
                <Shortcuts />
                <Breadcrumbs
                  crumbs={[
                    {label: 'Dashboard', url: '/dashboard'},
                    {label: 'Cotação do Dólar', url: '/Dolar'},
                  ]}
                />

                <h5>Editar dólar</h5>
                <div className="row">
                  <div className="divider" />
                  <form className="col s12">
                    <div className="row">
                      <div className="input-field col s6">
                        <input
                          id="dolarQuotation"
                          type="text"
                          className="validate"
                          name="dolarQuotation"
                        />
                        <label htmlFor="dolarQuotation">Dólar</label>
                      </div>
                    </div>
                    <div className="divider" />
                    <a className="btn white black-text">
                      <i className="material-icons left">refresh</i>
                      Voltar
                    </a>
                    <button
                      type="submit"
                      className="btn orange white-text">
                      <i className="material-icons left">edit</i>
                      Editar
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Dolar;
