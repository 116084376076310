import React from 'react';

const SentPackagesAPI = {
  async getPackagesCount() {
    return (await this.getPackages()).length;
  },

  async getPrintedLabelsPackagesCount() {
    return (await this.getPrintedLabelsPackages()).length;
  },

  async getNotPrintedLabelsPackagesCount() {
    return (await this.getNotPrintedLabelsPackages()).length;
  },

  async getPackages() {
    return [
      {
        id: '1',
        packageId: '60257',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        weight: 6,
        value: 86.41,
        trackings: [{id: '449948848924'}, {id: '449948848923'}],
        type: 'fc',
        status: 'Enviado',
        confirmedPackage: false,
      },
    ];
  },

  async getPrintedLabelsPackages() {
    return [
      {
        id: '1',
        packageId: '60257',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        weight: 6,
        value: 86.41,
        trackings: [{id: '449948848924'}, {id: '449948848923'}],
        type: 'fc',
        status: 'Enviado',
        confirmedPackage: false,
      },
      {
        id: '2',
        packageId: '60256',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Nivalmir'},
        weight: 3,
        value: 86.41,
        trackings: [{id: '449948848924'}, {id: '449948848923'}],
        type: 'fp',
        status: 'Enviado',
        confirmedPackage: false,
      },
    ];
  },

  async getNotPrintedLabelsPackages() {
    return [
      {
        id: '1',
        packageId: '60257',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Vanessa'},
        weight: 6,
        value: 86.41,
        trackings: [{id: '449948848924'}, {id: '449948848923'}],
        type: 'fc',
        status: 'Enviado',
        confirmedPackage: false,
      },
      {
        id: '2',
        packageId: '60256',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Nivalmir'},
        weight: 4,
        value: 86.41,
        trackings: [{id: '449948848924'}, {id: '449948848923'}],
        type: 'fc',
        status: 'Enviado',
        confirmedPackage: false,
      },
      {
        id: '3-',
        packageId: '60256',
        date: '2019-05-14T16:32:08.746Z',
        user: {id: '41788', name: 'Nivalmir'},
        weight: 1,
        value: 86.41,
        trackings: [{id: '449948848924'}, {id: '449948848923'}],
        type: 'fc',
        status: 'Enviado',
        confirmedPackage: false,
      },
    ];
  },
};

export default SentPackagesAPI;
