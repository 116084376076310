import 'datejs';

const Helper = {
  formatDate(format, date) {
    return new Date(date).toString(format);
  },

  formatCurrency(value, symbol = 'US$') {
    return symbol + ' ' + value;
  },
};

export default Helper;
